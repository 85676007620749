import axios from "axios";
import store from "@/store";

const state = {
    signupData: null,
};

const getters = {
    signupData(state) {
        return state.signupData
    },
}

const actions = {
  async setSignupData({ commit }, payload) {
    const token = localStorage.getItem("auth-token");
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}signup-data/${payload}` ,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    //   commit("setSubscription", res.data.payload);
    //   return res.data.payload;
      commit("setSignUpData", res.data.payload);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setSignUpData(state, payload) {
    state.signupData = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};