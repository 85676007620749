import store from '@/store';
import firebase from 'firebase/app';
import 'firebase/auth';
import { db } from '../../firebase'
import router from '../../router'

const state = {
    userExists: false,
    userExistsDetails: {},
};

const getters = {

    userExists (state) {
        return state.userExists 
    },
    userExistsDetails (state) {
      return state.userExistsDetails
    }
};

const actions = {
  
  globalCheckIfUserEmailExist({commit}, payload) {
    console.log("globalCheckIfUserEmailExist")
    console.log(payload);

    db.collection("users")
    .where("email", "==", payload)
    .get()
    .then(function(querySnapshot) {
        const collection = [];
        querySnapshot.forEach(doc => {
          collection.push({ "id": doc.id, "user": doc.data() });
          });
          console.log(collection);
          if(collection.length > 0) {
            console.log("true");
            commit('setUserExists', true);
            commit('setUserExistsDetails', collection);
          } else {
            console.log("false");
            commit('setUserExistsDetails', {});
            commit('setUserExists', false);
          } 
    });
  }
      
}

const mutations = {
      setUserExists(state, payload) {
        state.userExists = payload;
      },
      setUserExistsDetails(state, payload) {
        state.userExistsDetails = payload;
      }
};

export default {
    state,
    getters,
    actions,
    mutations
}