import axios from "axios";


const token = localStorage.getItem('auth-token')
export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    Authorization: token ? `Bearer ${token}` : '',
    // Authorization: "Bearer 116|s85zvNXQFuup9EZ0RS8SzJEI9HPFRC7lWZqDCsGQ",
  },
});
