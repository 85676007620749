import { db } from '../../firebase'

const state = {
    // title: null,
    // subtitle: null,
    // description: null,
    // reason: null,
    // semester: null,
    // level: null,
    // languages: null,
    // tags: null,
    // status: null, //draft, published
    // studentGroup: null,
    // country: null,
    // countryRegion: null,
    // travelReagion: null,
    users: [],
    companies: [],
    collaborations: [],
};

const getters = {
    // title (state) {
    //     return state.title
    // } 
    // user (state) {
    //     return state.user
    // },
    // error (state) {
    //     return state.error
    // }
};

const actions = {

    async getUserList({ commit }) { //{ commit }, payload
    //TODO: test
       db
       .collection('users')
       .get()
       .then(snap => {
           const collection = [];
            snap.forEach(doc => {
             collection.push({ "id": doc.id, "user": doc.data() });
            });
           // this.collection = collection;
           commit("setList", collection)
       });
     },

     async getCompanyList({ commit }) { //{ commit }, payload
        db
        .collection('companies')
        .get()
        .then(snap => {
            const collection = [];
            snap.forEach(doc => {
                collection.push({ "id": doc.id, "company": doc.data() });
            });
            // this.collection = collection;
            commit("setCompanyList", collection)
        });
    },

    //COLLABORATIONS
    //GET
    async getAdminCollaborations({ commit }) {
        // console.log("getAdminCollaborations")
        db
        .collection('collaborations')
        .get()
        .then(function(querySnapshot) {
            const collection = [];
            querySnapshot.forEach(doc => {
              collection.push({ "id": doc.id, "collaboration": doc.data() });
             });
            commit("setCollaborations", collection);
        });
      },
      //DELETE
      async deleteAdminCollaboration({ commit }, collabId) {
        db.collection("collaborations").doc(collabId).delete().then(function() {
            // console.log("Document successfully deleted!");
        }).catch(function(error) {
            console.error("Error removing document: ", error);
        });
      },
  }

const mutations = {
    // updateProposal (state, payload) {
    //   state.title = payload.title;
    //   state.subtitle = payload.subtitle;
    // },
    setCollaborations(state, payload) {
        state.collaborations = payload;
      },
    setList(state, payload) {
      state.users = payload; 
    },
    setCompanyList(state, payload) {
        state.companies = payload; 
      }
};

export default {
    state,
    getters,
    actions,
    mutations
}