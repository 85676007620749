import axios from 'axios';
import { db } from "../../firebase";
import firebase from "firebase/app";
import router from "../../router";
import store from "@/store";

const state = {
  //collaboration
  status: null, //draft, published, offered, collaborating, deleted
  substatus: null, //used for excelerate status, like ghosts and stuff :)
  //thesis stuff
  newCollabId: null,
  title: null,
  subtitle: null,
  content: null,
  pitch: null,
  targetSemester: null,
  numberOfStudents: 0,
  studentEmails: {},
  // collaborationStart: null,

  country: null,
  institution: null,
  faculty: null,
  degree: null,
  area: null,
  languages: [],
  //tags: null,

  //dates
  createdDate: null,
  lastEditedDate: null,
  endDate: null, //graduatingSemester

  //stats
  viewCount: 0,

  //collaboration members
  invites: [],
  members: [],

  //offers
  offers: [], //requests from companies

  error: null, //
  getUserIdByEmail: false,

  //states to show data
  collaborations: [],
  collaborationMembers: [],
  usersCollaborations: [],
  collaborationInvites: [],
  collaborationOffers: [],
  // newly added
  collaborationDetails: {},
  stepCompleted: 0,
};

const getters = {
  // newly added
  newCollabId(state) {
    return state.newCollabId;
  },
  numberOfStudents(state) {
    return state.numberOfStudents;
  },
  studentEmails(state) {
    return state.studentEmails;
  },
  // newly added
  collaborationDetails(state) {
    return state.collaborationDetails;
  },
  title(state) {
    return state.title;
  },
  subtitle(state) {
    return state.subtitle;
  },
  content(state) {
    return state.content;
  },
  pitch(state) {
    return state.pitch;
  },
  targetSemester(state) {
    return state.targetSemester;
  },
  languages(state) {
    return state.languages;
  },
  members(state) {
    return state.members;
  },
  invites(state) {
    return state.invites;
  },
  collaborationMembers(state) {
    return state.collaborationMembers;
  },
  collaborationInvites(state) {
    return state.collaborationInvites;
  },
  offers(state) {
    return state.offers;
  },
  substatus(state) {
    return state.substatus;
  },
  stepCompleted(state) {
    return state.stepCompleted;
  },
  getUserIdByEmail(state) {
    return state.getUserIdByEmail;
  },
};

const actions = {
  // newly added
  // async getCollaboration({ commit }, collabId) {
  //   var collection = [];
  //   var ref = db.collection("users").doc(collabId);
  //   ref.get()
  //   .then(function(querySnapshot) {
  //     let collection;

  //          collection = querySnapshot.data();
  //         //  console.log(collection)

  //         //  console.log(ref, collection)

  //     commit("setCollaborationDetail",collection);
  //   })
  //   .catch(function(error) {
  //       console.log("Error getting documents: ", error);
  //   });
  //   return collection;

  // db
  // .collection("collaborations")
  // .where("status", "==", "draft")
  // .get()
  // .then(function(querySnapshot) {
  //    let collection;
  //     querySnapshot.forEach(doc => {
  //      collection =  ({ "id": doc.id, "collaboration": doc.data() });
  //      });
  //      console.log(collection)
  //     commit("setCollaborationDetail",collection);
  // });
  // },
  async getCollaborations({ commit }) {
    db.collection("collaborations")
      .where("status", "==", "published")
      .get()
      .then(function(querySnapshot) {
        const collection = [];
        querySnapshot.forEach((doc) => {
          // console.log(doc.data())
          collection.push({ id: doc.id, collaboration: doc.data() });
        });
        commit("setCollaborations", collection);
      });
  },

  async addCollaboration({ commit }, payload) {
    var ref = db.collection("collaborations").doc();
    var autoId = ref.id;
    // var createdDate = new Date();

    ref
      .set(
        {
          isNew: payload.collaborationDetails.isNew,
          status: "draft",
          substatus: "",
          title: payload.collaborationDetails.title,
          subtitle: payload.collaborationDetails.subtitle,
          pitch: payload.collaborationDetails.pitch,
          content: payload.collaborationDetails.content,
          // methodology: payload.collaborationDetails.methodology,
          value: payload.collaborationDetails.value,
          motivation: payload.collaborationDetails.motivation,
          whyus: payload.collaborationDetails.whyus,
          targetSemester: payload.collaborationDetails.targetSemester,
          languages: payload.collaborationDetails.languages,
          numberOfStudents: payload.collaborationDetails.numberOfStudents,
          firstStartDate: payload.collaborationDetails.firstStartDate,
          lastStartDate: payload.collaborationDetails.lastStartDate,
          thesisHandInDate: payload.collaborationDetails.thesisHandInDate,
          studentEmails: payload.collaborationDetails.studentEmails,
          stepsStatus: payload.collaborationDetails.stepsStatus,
          //from user
          keywords: payload.collaborationDetails.keywords,
          faculty: payload.user.faculty,
          country: payload.user.country,
          area: payload.user.area,
          degree: payload.user.degree,
          institution: payload.user.institution,
          createdDate: firebase.firestore.FieldValue.serverTimestamp(),
          lastEditedDate: firebase.firestore.FieldValue.serverTimestamp(),
          interests: [payload.user.interests],
          endDate: "",
          viewCount: 0,
          members: [payload.user.userId],
          invites: [],
          offers: [],
          stepCompleted: payload.collaborationDetails.stepCompleted,
        },
        { merge: true }
      )
      .then(function() {
        //save id on user
        commit("setNewCollaborationId", autoId);

        // router.push("/collaborations/" + autoId);
        router.push("/collaboration/edit/" + autoId + "?new=collaboration");
        // console.log( state.newCollabId)
        return state.newCollabId;
      })
      .catch(function(error) {
        //todo log
        console.error("Error writing document: ", error);
      });
  },
  // nely added
  async collaborationForReview({ commit, state }, payload) {
    var ref = db.collection("collaborations").doc();
    var autoId = ref.id;
    // var createdDate = new Date();

    ref
      .set(
        {
          status: "review",
          substatus: "",
          title: payload.collaborationDetails.title,
          subtitle: payload.collaborationDetails.subtitle,
          pitch: payload.collaborationDetails.pitch,
          content: payload.collaborationDetails.content,
          targetSemester: payload.collaborationDetails.targetSemester,
          languages: payload.collaborationDetails.languages,
          numberOfStudents: payload.collaborationDetails.numberOfStudents,
          firstStartDate: payload.collaborationDetails.firstStartDate,
          lastStartDate: payload.collaborationDetails.lastStartDate,
          thesisHandInDate: payload.collaborationDetails.thesisHandInDate,
          studentEmails: payload.collaborationDetails.studentEmails,
          //from user
          keywords: payload.collaborationDetails.keywords,
          faculty: payload.user.faculty,
          country: payload.user.country,
          area: payload.user.area,
          degree: payload.user.degree,
          institution: payload.user.institution,
          createdDate: firebase.firestore.FieldValue.serverTimestamp(),
          lastEditedDate: firebase.firestore.FieldValue.serverTimestamp(),
          interests: [payload.user.interests],
          endDate: "",
          viewCount: 0,
          // members: payload.collaborationDetails.members,
          invites: [],
          offers: [],
          stepCompleted: payload.collaborationDetails.stepCompleted,
        },
        { merge: true }
      )
      .then(function() {
        // console.log("collaboration sent for review");
        commit("setNewCollaborationId", autoId);
        // console.log(state.newCollabId);
        return state.newCollabId;
      })
      .catch(function(error) {
        //todo log
        console.error("Error in sending the collaboration for review: ", error);
      });
  },
  // newly added
  async updateCollaborationDetail({ commit }, payload) {
    console.log(payload.collaborationDetails.stepsStatus, "pp");
    var ref = db.collection("collaborations").doc(payload.id);
    // console.log(payload)
    var refId = payload.id;
    // console.log(ref)
    // var editedDate = new Date();
    // let collaboration = payload.collaborationDetails;
    // for (const key in collaboration) {
    //   if (Object.hasOwnProperty.call(collaboration, key)) {
    //     const element = collaboration[key];
    //     console.log(key,collaboration[key],typeof collaboration[key])

    //   }
    // }
    // payload.collaborationDetails.forEach(collab=>console.log(collab));

    ref
      .set(
        {
          // isNew:payload.collaborationDetails.isNew,
          title: payload.collaborationDetails.title,
          subtitle: payload.collaborationDetails.subtitle,
          pitch: payload.collaborationDetails.pitch,
          content: payload.collaborationDetails.content,
          // methodology: payload.collaborationDetails.methodology,
          value: payload.collaborationDetails.value,
          motivation: payload.collaborationDetails.motivation,
          whyus: payload.collaborationDetails.whyus,
          targetSemester: payload.collaborationDetails.targetSemester,
          languages: payload.collaborationDetails.languages,
          numberOfStudents: payload.collaborationDetails.numberOfStudents,
          firstStartDate: payload.collaborationDetails.firstStartDate,
          lastStartDate: payload.collaborationDetails.lastStartDate,
          thesisHandInDate: payload.collaborationDetails.thesisHandInDate,
          studentEmails: payload.collaborationDetails.studentEmails,
          stepsStatus: payload.collaborationDetails.stepsStatus,
          //from user
          keywords: payload.collaborationDetails.keywords,
          faculty: payload.user.faculty,
          country: payload.user.country,
          area: payload.user.area,
          degree: payload.user.degree,
          institution: payload.user.institution,
          // createdDate: firebase.firestore.FieldValue.serverTimestamp(),
          lastEditedDate: firebase.firestore.FieldValue.serverTimestamp(),
          interests: [payload.user.interests],
          endDate: "",
          viewCount: 0,
          // members: [payload.user.userId],
          // members: payload.collaborationDetails.members,
          invites: [],
          offers: [],
          stepCompleted: payload.collaborationDetails.stepCompleted,
        },
        { merge: true }
      )
      .then(function() {
        //save id on user
        // router.push("/collaborations/" + refId);
        // router.push("/collaboration/edit/"+refId)
      })
      .catch(function(error) {
        //todo log
        console.error("Error writing document: ", error);
      });
  },

  async updateCollaboration({ commit }, payload) {
    var ref = db.collection("collaborations").doc(payload.id);
    var refId = payload.id;
    // var editedDate = new Date();

    ref
      .set(
        {
          status: payload.collaborationDetails.status,
          //New
          numberOfStudents: payload.collaborationDetails.numberOfStudents,
          firstStartDate: payload.collaborationDetails.firstStartDate,
          lastStartDate: payload.collaborationDetails.lastStartDate,
          thesisHandInDate: payload.collaborationDetails.thesisHandInDate,
          studentEmails: payload.collaborationDetails.studentEmails,
          //new
          title: payload.collaborationDetails.title,
          subtitle: payload.collaborationDetails.subtitle,
          pitch: payload.collaborationDetails.pitch,
          content: payload.collaborationDetails.content,
          // methodology: payload.collaborationDetails.methodology,
          value: payload.collaborationDetails.value,
          motivation: payload.collaborationDetails.motivation,
          whyus: payload.collaborationDetails.whyus,
          targetSemester: payload.collaborationDetails.targetSemester,
          languages: payload.collaborationDetails.languages,
          //from user
          lastEditedDate: firebase.firestore.FieldValue.serverTimestamp(),
          stepCompleted: payload.collaborationDetails.stepCompleted,
        },
        { merge: true }
      )
      .then(function() {
        //save id on user
        router.push("/collaborations/" + refId);
      })
      .catch(function(error) {
        //todo log
        console.error("Error writing document: ", error);
      });
  },

  async updateCollaborationStatus({ commit }, payload) {
    const status = payload.newStatus;

    var ref = db.collection("collaborations").doc(payload.id);
    var refId = payload.id;
    // var editedDate = new Date();

    ref
      .update({
        status: status,
        lastEditedDate: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(function() {
        //save id on user
        if (status !== "deleted" && status !== "collaborating") {
          if (payload.redirect) {
            router.push("/collaborations/" + refId);
          }
        } else if (status === "collaborating") {
          //send mail...
          //new invoice
          store.dispatch("newCollaborationOrder", payload);
        } else {
          router.push("/collaboration/my");
        }
      })
      .catch(function(error) {
        //todo log
        console.error("Error writing document: ", error);
      });
  },

  async setThesisSlots({ commit }, payload) {
    const token = localStorage.getItem("auth-token");
    try {
      const res = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}subscriptions/set-thesis-slots`,
        {
          subscription_id: payload.subscription_id,
          company_id: payload.company_id,
          thesis_semester: payload.thesis_semester,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      commit("setAllThesis", res.data.payload)
    } catch (error) {
      console.log(error)
    }
  },

  async newCollaborationOrder({ commit }, payload) {
    var ref = db.collection("collaborationOrders").doc();
    ref
      .set(
        {
          offerDetails: payload.offerDetails,
          members: payload.members,
          matchPrice: payload.matchPrice,
          acceptedByUserId: payload.acceptedBy,
          //from user
          createdDate: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      )
      .then(function() {})
      .catch(function(error) {
        //todo log
        console.error("Error writing document: ", error);
      });
  },

  //
  // INVITES && MEMBERSHIP
  async inviteUserToCollabortion({ commit }, payload) {
    var ref = db.collection("collaborationInvites").doc();
    ref
      .set({
        collaborationId: payload.collaborationId,
        collaborationTitle: payload.collaborationTitle,
        sender: payload.sender,
        senderEmail: payload.senderEmail,
        targetEmail: payload.targetEmail,
        sendDate: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(function() {
        db.collection("collaborations")
          .doc(payload.collaborationId)
          .update({
            numberOfStudents: firebase.firestore.FieldValue.increment(1),
          });

        // console.log("saved");
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });
  },

  async acceptCollaborationInvitation({ commit }, payload) {
    const collaborationId = payload.collaborationId;
    const inviteId = payload.inviteId;
    const currentUser = await firebase.getCurrentUser();
    const existingMembers = payload.existingMembers;
    existingMembers.push(currentUser.uid);

    const inviteData = {
      collabId: collaborationId,
      inviteId: inviteId,
    };

    db.collection("collaborations")
      .doc(collaborationId)
      .update({
        members: existingMembers,
        interests: firebase.firestore.FieldValue.arrayUnion(payload.interests),
      })
      .then(function() {
        store.dispatch("deleteCollaborationInviteByIdAlt", inviteData);
        // store.dispatch("deleteCollaborationInviteById", inviteData);
      })
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });
  },
  async acceptCollaborationInvitationFromHome({ commit }, payload) {
    const collaborationId = payload.collaborationId;
    const inviteId = payload.inviteId;
    const currentUser = await firebase.getCurrentUser();
    const inviteData = {
      collabId: collaborationId,
      inviteId: inviteId,
    };
    // console.log(inviteData)
    var ref = db.collection("collaborations").doc(collaborationId);
    ref
      .get()
      .then(function(querySnapshot) {
        let collection;

        collection = querySnapshot.data().members;
        const existingMembers = collection;
        existingMembers.push(currentUser.uid);
        db.collection("collaborations")
          .doc(collaborationId)
          .update({
            members: existingMembers,
            interests: firebase.firestore.FieldValue.arrayUnion(
              payload.interests
            ),
          })
          .then(function() {
            // console.log(inviteData)
            // store.dispatch("deleteCollaborationInviteById", inviteData);
            store.dispatch("deleteCollaborationInviteByIdAlt", inviteData);
            router.push("/collaborations/" + collaborationId);
          })
          .catch(function(error) {
            console.error("Error writing document: ", error);
          });

        // commit("setCollaborationDetail",collection);
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
      });
    // return collection;
  },

  async deleteCollaborationInviteById({ commit }, payload) {
    db.collection("collaborationInvites")
      .doc(payload.inviteId)
      .delete()
      .then(function() {
        store.dispatch("getCollaborationInvitesByCollabId", payload.collabId);
        db.collection("collaborations")
          .doc(payload.collabId)
          .update({
            numberOfStudents: firebase.firestore.FieldValue.increment(-1),
          });
      })
      .catch(function(error) {
        console.error("Error removing document: ", error);
      });
  },
  async deleteCollaborationInviteByIdAlt({ commit }, payload) {
    db.collection("collaborationInvites")
      .doc(payload.inviteId)
      .delete()
      .then(function() {
        store.dispatch("getCollaborationInvitesByCollabId", payload.collabId);
        // db.collection("collaborations")
        //     .doc(payload.collabId)
        //     .update({
        //         numberOfStudents: firebase.firestore.FieldValue.increment(-1)
        //     });
      })
      .catch(function(error) {
        console.error("Error removing document: ", error);
      });
  },

  async deleteCollaborationInvite({ commit }, payload) {
    var ref = db.collection("collaborationInvites").doc(payload.inviteId);
    ref
      .get()
      .then(function(querySnapshot) {
        var collab = querySnapshot.data();
        ref
          .delete()
          .then(function() {
            store.dispatch("getCollaborationInvitesByUserId");
            db.collection("collaborations")
              .doc(collab.collaborationId)
              .update({
                numberOfStudents: firebase.firestore.FieldValue.increment(-1),
              });
          })
          .catch(function(error) {
            console.error("Error removing document: ", error);
          });
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
      });

    // db.collection("collaborationInvites").doc(payload.inviteId).delete().then(function() {
    //   // store.dispatch("getCollaborationInvitesByCollabId", payload.collabId);

    // }).catch(function(error) {
    //   console.error("Error removing document: ", error);
    // });
  },

  async updateCollaborationMembers({ commit }, payload) {
    db.collection("collaborations")
      .doc(payload.collaborationId)
      .update({
        members: payload.members,
      })
      .then(function() {})
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });
  },
  async updateCollaborationKeywords({ commit }, payload) {
    db.collection("collaborations")
      .doc(payload.collaborationId)
      .update({
        keywords: payload.keywords,
      })
      .then(function() {})
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });
  },

  async getCollaborationInvitesByUserId({ commit }) {
    const currentUser = await firebase.getCurrentUser();

    var ref = db.collection("collaborationInvites");
    ref
      .where("targetEmail", "==", currentUser.email)
      .get()
      .then(function(querySnapshot) {
        const collection = [];
        querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          collection.push({ id: doc.id, invites: doc.data() });
        });
        commit("setCollaborationInvites", collection);
      })
      .catch(function(error) {
        console.error("Error getting documents: ", error);
      });
  },

  // INVITES && MEMBERSHIP END

  async getCollaborationByUserId({ commit }, user) {
    var currentUserId = user.userId;
    var ref = db.collection("collaborations");
    ref
      .where("members", "array-contains", currentUserId)
      .get()
      .then(function(querySnapshot) {
        const collection = [];
        querySnapshot.forEach(function(doc) {
          //resolve members from their IDs
          const collabMembers = doc.data().members;
          var membersResolved = [];
          collabMembers.forEach(function(member, index) {
            db.collection("users")
              .doc(member)
              .get()
              .then(function(querySnapshot) {
                const memberCollection = querySnapshot.data();
                membersResolved.push(memberCollection);
              })
              .catch(function(error) {
                console.error("Error getting documents: ", error);
              });
          });
          collection.push({
            id: doc.id,
            collaboration: doc.data(),
            resolvedMembers: membersResolved,
          });

          //kræl - refactor
          setTimeout(function() {
            commit("setUsersCollaborations", collection);
          }, 200);
        });
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
      });
  },

  async getCollaborationInvitesByCollabId({ commit }, collabId) {
    var ref = db.collection("collaborationInvites");
    ref
      .where("collaborationId", "==", collabId)
      .get()
      .then(function(querySnapshot) {
        const collection = [];
        querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          // console.log({ "id": doc.id, "invites": doc.data() })
          collection.push({ id: doc.id, invites: doc.data() });
        });
        commit("setCollaborationInvites", collection);
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
      });
  },

  //getCollaborationMemberByUserId
  async getCollaborationMemberById({ commit }, userId) {
    var collection = [];
    var ref = db.collection("users").doc(userId);
    ref
      .get()
      .then(function(querySnapshot) {
        collection = querySnapshot.data();
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
      });
    return collection;
  },

  //getUserIdByEmail
  async getUserIdByEmail({ commit }, email) {
    // console.log("email:", email);
    var collection = [];
    // emails.forEach(email=>{
    var ref = db.collection("users").where("email", "==", email);
    ref
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach((doc) => {
          // console.log("ok", doc.id,doc.data());
          // members.push(doc.id);
          collection.push(doc.id);
          // result = true;
        });
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
      });
    // })

    // return collection;
    commit("setUserIdByEmails", collection);
  },

  async getCollaborationMembers({ commit }, members) {
    // console.log(members)
    const result = [];
    members.forEach(function(memberId, index) {
      var ref = db.collection("users").doc(memberId);
      ref
        .get()
        .then(function(querySnapshot) {
          const collection = querySnapshot.data();
          result.push({ id: querySnapshot.id, members: querySnapshot.data() });
          // console.log({ "id":querySnapshot.id, "members": querySnapshot.data()})
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
        });
    });

    commit("setCollaborationMembers", result);
  },
  // async deleteCollaborationMember({commit}, members) {
  //   console.log(members)
  //   const result = [];
  //   members.forEach(function(memberId, index) {
  //     var ref = db.collection("users").doc(memberId);
  //     ref.get()
  //     .then(function(querySnapshot) {
  //       const collection = querySnapshot.data();
  //       result.push({ "id":querySnapshot.id, "members": querySnapshot.data()});
  //       // console.log({ "id":querySnapshot.id, "members": querySnapshot.data()})
  //     })
  //     .catch(function(error) {
  //         console.log("Error getting documents: ", error);
  //     });
  //   });

  //   commit("setCollaborationMembers", result);
  // },

  //
  // OFFERS
  //
  async getCollaborationOffersCollabId({ commit }, collabId) {
    var ref = db.collection("collaborationOffers");
    ref
      .where("collaborationId", "==", collabId)
      .onSnapshot(function(querySnapshot) {
        // console.log(querySnapshot, querySnapshot.empty)
        if (!querySnapshot.empty) {
          const collection = [];
          querySnapshot.forEach(function(doc) {
            collection.push({ id: doc.id, offer: doc.data() });
          });
          commit("setCollaborationOffers", collection);
        } else {
          console.log("Error getting documents");
        }
      });
    // .catch(function(error) {
    //     console.log("Error getting documents: ", error);
    // });
  },

  async updateCollaborationOfferStatus({ commit }, payload) {
    var ref = db.collection("collaborationOffers").doc(payload.id);
    var result = {
      status: payload.newStatus,
      lastEdited: firebase.firestore.FieldValue.serverTimestamp(),
    };
    if (payload.newStatus === 2) {
      result.acceptedByStudentAt = firebase.firestore.FieldValue.serverTimestamp();
    } else if (payload.newStatus === 3) {
      result.confirmedByStudentAt = firebase.firestore.FieldValue.serverTimestamp();
    } else if (payload.newStatus === 4) {
      result.confirmedByCompanyAt = firebase.firestore.FieldValue.serverTimestamp();
    }
    ref
      .set(result, { merge: true })
      .then(function() {})
      .catch(function(error) {
        console.error("Error writing document: ", error);
      });
  },
  async updateCollaborationArrangedInterview({ commit }, payload) {
    const arrangedInterview = payload.arrangedInterview;

    var ref = db.collection("collaborationOffers").doc(payload.offerId);
    // var refId = payload.collaborationId;
    // var editedDate = new Date();

    ref
      .update({
        arrangedInterview: arrangedInterview,
        lastEdited: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(function() {
        //save id on user
        // console.warn("yes or no");
        // if (status !== "deleted" && status !== "collaborating") {
        //   if (payload.redirect) {
        //     router.push("/collaborations/" + refId);
        //   }
        // } else if (status === "collaborating") {
        //   //send mail...
        //   //new invoice
        //   store.dispatch("newCollaborationOrder", payload);
        // } else {
        //   router.push("/collaboration/my");
        // }
      })
      .catch(function(error) {
        //todo log
        console.error("Error writing document: ", error);
      });
  },
  async getThesisSlotsForSession({commit}, payload){
    const token = localStorage.getItem('auth-token');
    try {
      const res = await axios.post(`${process.env.VUE_APP_API_BASE_URL}subscriptions/thesis-slots`,{
        "subscription_id": payload.subscription,
        "company_id": payload.company,
        "thesis_semester": payload.semester
    },
      {
        headers:{
          'Authorization': 'Bearer '+ token,
        }
      })
      // console.log(res.payload, 'payload')
      // return res.payload
      return res.data.payload[0];
      // console.log(res.data.payload[0])
    } catch (error) {
      console.log(error)
    }
  }
};

const mutations = {
  setCollaborations(state, payload) {
    state.collaborations = payload;
  },
  setCollaboration(state, payload) {
    state.title = payload.title;
    state.subtitle = payload.subtitle;
    state.content = payload.content;
    state.pitch = payload.pitch;
    state.targetSemester = payload.targetSemester;
    state.degree = payload.degree;
    state.languages = payload.languages; //tags: null,
    state.status = payload.status; //draft, published, offered, ended, deleted

    // state.methodology = payload.methodology;
    state.value = payload.value;
    state.motivation = payload.motivation;
    state.whyus = payload.whyus;
    state.numberOfStudents = payload.numberOfStudents;
    state.firstStartDate = payload.firstStartDate;
    state.lastStartDate = payload.lastStartDate;
    state.thesisHandInDate = payload.thesisHandInDate;
    state.studentEmails = payload.studentEmails;
    //from user
    state.keywords = payload.keywords;
    state.faculty = payload.faculty;
    state.country = payload.country;
    state.area = payload.area;
    state.institution = payload.institution;
    // createdDate= firebase.firestore.FieldValue.serverTimestamp();
    state.lastEditedDate = payload.lastEditedDate;
    state.interests = payload.interests;
    state.endDate = "";
    state.viewCount = 0;
    // members= [payload.user.userId];
    // members= payload.members;
    state.stepCompleted = payload.stepCompleted;
  },
  // newly added
  setCollaborationDetail(state, payload) {
    state.collaborationDetails = payload;
  },
  setUsersCollaborations(state, payload) {
    state.usersCollaborations = payload;
  },
  setCollaborationMembers(state, payload) {
    state.collaborationMembers = payload;
  },
  setCollaborationInvites(state, payload) {
    state.collaborationInvites = payload;
  },
  setCollaborationOffers(state, payload) {
    state.collaborationOffers = payload;
  },
  setNewCollaborationId(state, payLoad) {
    state.newCollabId = payLoad;
  },
  setUserIdByEmails(state, payload) {
    state.getUserIdByEmail = payload;
  },
  setDesiredOccupation(state, payload) {
    state.getDesiredOccupation = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
