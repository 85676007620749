import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from '@/firebase.js';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/signin',
  },
  {
    path: '/user/forgot-password',
    redirect: '/forgot-password',
  },
  {
    path: '/signup',
    redirect: '/student-signup',
    // component: () => import("../views/auth/SignUp.vue"),
  },
  {
    path: '/user/signup/:role?',
    redirect: '/student-signup',
    // component: () => import("../views/auth/SignUp.vue"),
  },
  {
    path: '/user',
    component: () => import('../views/UserMaster.vue'),
    children: [
      {
        path: '/student-signup',
        component: () => import('../views/auth/SignUp.vue'),
      },
      // route to confirm otp and activate account
      {
        path: '/professional-signup/onboarding/:uid',
        component: () => import('../views/auth/OnboardingProfessional.vue'),
      },
      // added the requiresInvite meta because we don't want all users
      //landing on this page for no reason unless they are coming from the onboarding step
      {
        path: '/professional-signup/onBoarded',
        component: () => import('../views/auth/OnBoarded.vue'),
        meta: {
          requiresInvite: true,
        },
      },
      {
        path: '/professional-signup/onboarding',
        component: () => import('../views/auth/OnboardingForm.vue'),
        meta: {
          requiresInvite: true,
        },
      },
      {
        path: '/professional-signup',
        component: () => import('../views/auth/SignUpProfessional.vue'),
      },
      {
        path: '/user',
        component: () => import('../views/auth/SignIn.vue'),
      },
      {
        path: '/signin',
        name: 'sign-in',
        component: () => import('../views/auth/SignIn.vue'),
      },
      {
        path: '/forgot-password',
        component: () => import('../views/auth/forgotPassword.vue'),
      },
      {
        path: '/signup/:role?',
        component: () => import('../views/auth/SignUp.vue'),
      },
      {
        path: '/user/terms',
        component: () => import('../views/public/Terms.vue'),
        meta: {
          title: 'Our terms and conditions',
          //check if role is selected...
        },
      },
      {
        path: '/user/verify',
        component: () => import('../views/auth/Verify.vue'),
        meta: {
          requiresAuth: true,
          //check if role is selected...
        },
      },
      {
        path: '/user/welcome',
        component: () => import('../views/user/Welcome.vue'),
        meta: {
          requiresAuth: true,
          //check if role is selected...
        },
      },
      {
        path: '/user/student-welcome',
        component: () => import('../views/user/StudentRegister.vue'),
        meta: {
          requiresAuth: true,
          //check if role is selected...
        },
      },
      // enable or no more rising star functionality, lmao
      {
        path: '/user/student-rising-star',
        component: () => import('../views/user/StudentRegisterNext.vue'),
        meta: {
          requiresAuth: true,
          //check if role is selected...
        },
      },
      {
        path: '/user/student-talent/unlock',
        component: () =>
          import('../views/user/studentRegisterTalentUnlock.vue'),
        meta: {
          requiresAuth: true,
          //check if role is selected...
        },
      },
      {
        path: '/user/student-talent/industry',
        component: () =>
          import('../views/user/studentRegisterTalentIndustry.vue'),
        meta: {
          requiresAuth: true,
          //check if role is selected...
        },
      },
      {
        path: '/user/student-talent/matching',
        component: () =>
          import('../views/user/studentRegisterTalentMatching.vue'),
        meta: {
          requiresAuth: true,
          //check if role is selected...
        },
      },
      {
        path: '/user/student-talent/complete',
        component: () =>
          import('../views/user/studentRegisterTalentComplete.vue'),
        meta: {
          requiresAuth: true,
          //check if role is selected...
        },
      },
    ],
  },

  {
    path: '',
    component: () => import('../views/AppMaster.vue'),
    children: [
      {
        path: '/',
        component: () => import('../views/app/Dashboard.vue'),
        meta: {
          requiresAuth: true,
          title: 'Your dashboard',
        },
      },
      {
        path: '/dashboard/',
        component: () => import('../views/app/Dashboard.vue'),
        meta: {
          requiresAuth: true,
          title: 'Your dashboard',
        },
      },
      {
        path: '/student-dashboard/',
        component: () => import('../views/app/StudentDashboard.vue'),
        meta: {
          requiresAuth: true,
          title: 'Your dashboard',
        },
      },
      {
        path: '/user/profile-edit',
        component: () => import('../views/user/EditUser.vue'),
        meta: {
          requiresAuth: true,
          title: 'Edit Profile',
          //check if role is selected...
        },
      },
      {
        path: '/user/edit',
        component: () => import('../views/auth/UserEdit.vue'),
        meta: {
          requiresAuth: true,
          title: 'Edit profile',
        },
      },
      {
        path: '/dashboard/:widget',
        component: () => import('../views/app/Dashboard.vue'),
        meta: {
          requiresAuth: true,
          title: 'Your dashboard',
        },
      },
      {
        path: '/profile',
        component: () => import('../views/auth/UserProfile.vue'),
        meta: {
          requiresAuth: true,
          title: 'User profile',
        },
      },
      {
        path: '/events',
        name: 'events',
        component: () => import('../views/Events/Events.vue'),
        meta: {
          requiresAuth: true,
          title: 'Events',
        },
      },
      {
        path: '/events/ongoing/:id',
        name: 'ongoingevent',
        component: () => import('../views/Events/Event.vue'),
        meta: {
          requiresAuth: true,
          title: 'Event',
        },
      },
      {
        path: '/events/past/:id',
        name: 'pastevent',
        component: () => import('../views/Events/Event.vue'),
        meta: {
          requiresAuth: true,
          title: 'Event',
        },
      },
      {
        path: '/events/future/:id',
        name: 'futureevent',
        component: () => import('../views/Events/Event.vue'),
        meta: {
          requiresAuth: true,
          title: 'Event',
        },
      },
      {
        path: '/events/student-qr',
        name: 'student qr code',
        component: () => import('../views/Events/StudentQr.vue'),
        meta: {
          requiresAuth: true,
          title: 'student qr code',
        },
      },
      {
        path: '/collaborations',
        name: 'app-collaborations',
        component: () => import('../views/collaborations/Collaborations.vue'),
        meta: {
          requiresAuth: true,
          title: 'Find collaborations',
        },
      },
      {
        path: '/collaborations/:id',
        name: 'collaboration-id',
        component: () =>
          import('../views/collaborations/ViewCollaborations.vue'),
        meta: {
          requiresAuth: false,
          title: 'View collaboration',
        },
      },
      // {
      //   path: '/collaboration/edit/:id',
      //   name: 'collaboration-edit',
      //   component: () => import('../views/collaborations/EditCollaboration.vue'),
      //   meta: {
      //     requiresAuth: true,
      //     title: "Edit collaboration"
      //   }
      // },
      {
        path: '/collaboration/new',
        name: 'new-collaboration',
        component: () => import('../views/collaborations/NewCollaboration.vue'),
        meta: {
          requiresAuth: true,
          title: 'New collaboration',
        },
      },
      {
        path: '/collaboration/edit/:id/',
        name: 'new-collaboration',
        component: () =>
          import('../views/collaborations/UpdateCollaboration.vue'),
        meta: {
          requiresAuth: true,
          title: 'Edit collaboration',
        },
      },
      {
        path: '/collaboration/my',
        name: 'my-collaboration',
        component: () => import('../views/collaborations/MyCollaborations.vue'),
        meta: {
          requiresAuth: true,
          title: 'My collaborations',
        },
      },
      {
        path: '/offer/:id',
        name: 'send-offer',
        component: () => import('../views/offers/NewOffer.vue'),
        meta: {
          requiresAuth: true,
          title: 'Send collaboration offer',
        },
      },
      {
        path: '/companies/:id',
        name: 'companies',
        component: () => import('../views/company/ViewCompany.vue'),
        meta: {
          requiresAuth: true,
          title: 'Company',
        },
      },
      {
        path: '/company/new',
        name: 'company-new',
        component: () => import('../views/company/NewCompany.vue'),
        meta: {
          requiresAuth: true,
          title: 'Register company',
        },
      },
      {
        path: '/subscription',
        name: 'subscription',
        component: () => import('../views/company/Subscription.vue'),
        meta: {
          requiresAuth: true,
          title: 'Subscription',
        },
      },
      {
        path: '/company/edit/:id',
        name: 'app-proposal',
        component: () => import('../views/company/EditCompany.vue'),
        meta: {
          requiresAuth: true,
          title: 'Edit company details',
        },
      },
      {
        path: '/company/terms',
        component: () => import('../views/public/TermsCollaborations.vue'),
        meta: {
          title: 'Our terms and conditions',
          //check if role is selected...
        },
      },
      {
        path: '/company-tokens/',
        component: () => import('../views/company/TokenDetails.vue'),
        meta: {
          requiresAuth: true,
          title: 'Company Token Details',
        },
      },
    ],
  },
  //admin pages
  //todo: check for user creds === one of us..
  //  {
  //   path: '/skynet/',
  //   name: 'dashboard',
  //   component: () => import('../views/admin/Skynet.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     title: "SkyNet v.1"
  //   }
  // },
];

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

//requiresAuth before routing
//todo check role status!
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresInvite = to.matched.some(
    (record) => record.meta.requiresInvite
  );
  const currentUser = await firebase.getCurrentUser();
  const param = to.params.id;
  const routePath = to.path.includes('collaborations');
  // ! thanks to firebase, the below part now needs to be written in every component that implements the student access blocking
  // const hasNewSignupFlow = store.getters.userProfileProgress >= 0 ? true : false;
  // const hasNotSignedUp = localStorage.getItem('notSignedUp');
  // if (!hasNotSignedUp) {
  //   if (!hasNewSignupFlow) {
  //     localStorage.setItem('notSignedUp', 1);
  //   } else {
  //     localStorage.removeItem('notSignedUp');
  //   }
  // }
  // console.log(store.getters.getAuthorized);
  if (
    store.getters.role === 'Professional' &&
    store.getters.getAuthorized === false &&
    (!to.path.includes('sign') || !to.path.includes('user/welcome'))
  ) {
    store.dispatch('signOutAction');
    next('/user/signin');
  }
  if (requiresAuth && !(await firebase.getCurrentUser())) {
    next('/user/signin');
  } else if (param == 'null' && !!routePath) {
    router.go(0); // pushing the user back to the current page (being token-details) because of route link fail
  } else if (requiresInvite && !store.getters.isOnboarding) {   
    // check if the user is not an invited user and redirect
    next('/user/signin');
  } else {
    // if(requiresAuth && currentUser.emailVerified) { //TODO IMPORTANT -> currentUser.emailVerified = !currentUser.emailVerified (false)
    //   if(to.path !== '/user/verify') {
    //     next('/user/verify');
    //   }
    // } else {
    //   next();
    // }
    //set titles
    document.title = to.meta.title || 'Excelerate Careers';
    next();
  }
});

export default router;
