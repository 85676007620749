import store from '@/store';
import { HTTP } from '../../axios/axios';

const state = {
  events: null,
};

const getters = {
  events(state) {
    return state.events;
  },
};

const actions = {
  async getEvents({ commit }, payload) {
    try {
      const res = await HTTP.get('getEvents');
      commit('setEvents', res.data.data.payload);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setEvents(state, payload) {
    state.events = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
