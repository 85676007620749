import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { firestorePlugin } from 'vuefire'
import VueMoment from 'vue-moment'
import VueJsonp from 'vue-jsonp'
import VueMeta from 'vue-meta'
import VueGtag from "vue-gtag";


Vue.use(VueGtag, {
  config: { id: "GTM-N5GP3MN" },
  pageTrackerScreenviewEnabled: true
},router);

Vue.use(VueJsonp)
Vue.use(firestorePlugin)
Vue.use(VueMoment);
Vue.use(VueMeta);

Vue.config.productionTip = false //todo

new Vue({
      router,
      store,
      created() {
        firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            this.$store.dispatch('autoSignIn', user)
          }
        });
      },
      render: h => h(App)
    }).$mount('#app')


