import Vue from 'vue'
import jsonp from 'jsonp'; //todo remove?
import queryString from 'query-string';

const state = {};

const getters = {};

const actions = {

    //actions
     subscribe({ commit }, payload) {
      const data = queryString.stringify({
        u: 'b4f8c427bf591fc3b46b5bb29',
        id: '17a04c7956',
        EMAIL: payload.subscriberEmail,
        FNAME: payload.subscriberUsername,
        ROLE: payload.subscriberRole,
      })     

      Vue.jsonp(`https://careers.us18.list-manage.com/subscribe/post?${data}`, this.onResponse).then(json => {
        console.log("success")
      }).catch(err => {
        console.log("err")
        // Failed.
      })
     },
     onResponse(error, data) {
       console.log("onResponse")
      // this.loading = false
    },

    formatErrorMessage(message) {
      return message.replace('0 - ', '')
    },
}

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
}