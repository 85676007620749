import store from '@/store';
import { HTTP } from '../../axios/axios';

const state = {
  industries: null,
};

const getters = {
  industries(state) {
    return state.industries;
  },
};

const actions = {
  async getIndustries({ commit }, payload) {
    try {
      const res = await HTTP.get('getIndustries');
      commit('setIndustries', res.data.data.payload);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setIndustries(state, payload) {
    state.industries = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
