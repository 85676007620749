import "firebase/storage";

import axios from "axios";
import { db } from "../../firebase";
import firebase from "firebase/app";
import router from "../../router";
import { storage } from "../../firebase";
import store from "@/store";

const state = {
  companyName: null,
  companyIdentificationNumber: null,
  companyAdmins: [],
  companyEmployees: [],

  companyInvites: [],
  usersCompanyInvites: [],

  //billing
  billingEmail: null,
  billingCountry: null,
  billingStreetAddress: null,
  billingPostCode: null,
  billingCity: null,
  subscription: null,
  publicSubscriptions: null,

  companyDetails: {}, //for offers - and edit?
  companyOffers: [],
  companyCollaborations: [],
};

const getters = {
  // title (state) {
  //     return state.title
  // }
  // user (state) {
  //     return state.user
  // },
  // error (state) {
  //     return state.error
  // }
  companyEmployees(state) {
    return state.companyEmployees;
  },
  companyAdmins(state) {
    return state.companyAdmins;
  },
  companyCollaborations(state) {
    return state.companyCollaborations;
  },
  companyOffers(state) {
    return state.companyOffers;
  },
  companyDetails(state) {
    return state.companyDetails;
  },
  usersCompanyInvites(state) {
    return state.usersCompanyInvites;
  },
  fetchedSubscription(state){
    return state.subscription;
  },
  clientSubscription(state){
    return state.subscription
  },
  
};

const actions = {
  async newCompany({ commit }, payload) {
    var ref = db.collection("companies").doc();
    var data = {
      docId: ref.id,
      userId: payload.user.userId,
      userDetails: payload.user
    };
    ref
      .set({
        companyName: payload.companyDetails.companyName,
        companyIdentificationNumber:
          payload.companyDetails.companyIdentificationNumber,
        companyAdmins: [data.userId],
        companyEmployees: [data.userId],
        companyInvites: [],
        created: firebase.firestore.FieldValue.serverTimestamp(),
        lastEdited: firebase.firestore.FieldValue.serverTimestamp(),
        billingEmail: payload.companyDetails.billingEmail,
        billingCountry: payload.companyDetails.billingCountry,
        billingStreetAddress: payload.companyDetails.billingStreetAddress,
        billingPostCode: payload.companyDetails.billingPostCode,
        billingCity: payload.companyDetails.billingCity,
        companyDescription: payload.companyDetails.description,
        companyURL: payload.companyDetails.websiteUrl,
        companyType: payload.companyDetails.companyType,
        companyLinkedinUrl: payload.companyDetails.linkedinUrl,
        industry: payload.companyDetails.industry,
        companySize: payload.companyDetails.companySize,
      })
      .then(() => {
        if (payload.companyDetails.image) {
          return firebase
            .storage()
            .ref("companylogos/" + payload.companyDetails.companyName)
            .putString(payload.companyDetails.image, "data_url", {
              contentType: "image/jpg",
            });
        }
      })

      .then(() => {
        if (payload.companyDetails.image) {
          const url = storage
            .refFromURL(
              "gs://excelerate2020.appspot.com/companylogos/" +
                payload.companyDetails.companyName
            )
            .getDownloadURL();
          return url;
        }
      })
      .then((imageUrl) => {
        if (payload.companyDetails.image) {
          // console.log(imageUrl);
          return db
            .collection("companies")
            .doc(data.docId)
            .update({ imageUrl: imageUrl });
        }
      })

      .then(function (docRef) {
        // console.log("Document written with ID: " + data.docId);
        store.dispatch("updateUserCompany", data.docId); //set users company id
      }).then(function () {
        const emailData = {
          toEmail: "nicolas@excelerate.dk",
          template: "new company registration",
          param0: payload.companyDetails.companyName,
          param1: data.userDetails.firstName,
          param2: data.userDetails.lastName,
          param3: data.userDetails.email,
          param4: data.userDetails.phoneNumber,
        };
        store.dispatch("sendMail", emailData);
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  },
  async updateCompany({ commit }, payload) {
    // commit('createCompany', payload);
    // console.log("updateCompany()");
    var ref = db.collection("companies").doc(payload.id);
    var refId = payload.id;
    let ext;

    ref
      .set(
        {
          companyName: payload.companyDetails.companyName,
          companyIdentificationNumber:
            payload.companyDetails.companyIdentificationNumber,
          lastEdited: firebase.firestore.FieldValue.serverTimestamp(),
          billingEmail: payload.companyDetails.billingEmail,
          billingCountry: payload.companyDetails.billingCountry,
          billingStreetAddress: payload.companyDetails.billingStreetAddress,
          billingPostCode: payload.companyDetails.billingPostCode,
          billingCity: payload.companyDetails.billingCity,
          companyDescription: payload.companyDetails.companyDescription,
          companyURL: payload.companyDetails.companyURL,
          companyType: payload.companyDetails.companyType,
          companyLinkedinUrl: payload.companyDetails.companyLinkedinUrl,
          industry: payload.companyDetails.industry,
          companySize: payload.companyDetails.companySize,
        },
        { merge: true }
      )
      .then(() => {
        if (payload.companyDetails.image) {
          return firebase
            .storage()
            .ref("companylogos/" + payload.companyDetails.companyName)
            .putString(payload.companyDetails.image, "data_url", {
              contentType: "image/jpg",
            });
        }
      })

      .then(() => {
        if (payload.companyDetails.image) {
          const url = storage
            .refFromURL(
              "gs://excelerate2020.appspot.com/companylogos/" +
                payload.companyDetails.companyName
            )
            .getDownloadURL();
          return url;
        }
      })
      .then((imageUrl) => {
        if (payload.companyDetails.image) {
          return db
            .collection("companies")
            .doc(payload.id)
            .update({ imageUrl: imageUrl });
        }
      })
      .then(function (docRef) {
        router.push("/companies/" + refId);
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  },

  async getCompanyDetails({ commit }, payload) {
    // commit('createCompany', payload);

    // console.log("getCompanyDetails");
    // console.log(payload);
    var ref = db
      .collection("companies")
      .doc(payload)
      .get()
      .then(function (querySnapshot) {
        const collection = querySnapshot.data();
        commit("setCompanyDetails", collection);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  },

  //Employees and admins
  async getCompanyEmployees({ commit }, members) {
    const result = [];
    members.forEach(function (memberId, index) {
      var ref = db.collection("users").doc(memberId);
      ref
        .get()
        .then(function (querySnapshot) {
          const collection = querySnapshot.data();
          result.push({ id: querySnapshot.id, user: querySnapshot.data() });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    });

    commit("setCompanyEmployees", result);
  },

  async getCompanyAdmins({ commit }, members) {
    const result = [];
    members.forEach(function (memberId, index) {
      var ref = db.collection("users").doc(memberId);
      ref
        .get()
        .then(function (querySnapshot) {
          const collection = querySnapshot.data();
          result.push({ id: querySnapshot.id, user: querySnapshot.data() });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    });

    commit("setCompanyAdmins", result);
  },

  async removeCompanyEmployeeByUserId({ commit }, payload) {
    db.collection("companies")
      .doc(payload.companyId)
      .update({
        companyEmployees: firebase.firestore.FieldValue.arrayRemove(
          payload.employee.id
        ),
        companyAdmins: firebase.firestore.FieldValue.arrayRemove(
          payload.employee.id
        ),
      })
      .then(function () {
        store.dispatch("removeUserCompany", payload.employee.id); //remove the company from the user
        commit("removeCompanyEmployee", payload.employee.id);

        //todo removeCompanyAdmin..
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  },

  async promoteCompanyAdminByUserId({ commit }, payload) {
    db.collection("companies")
      .doc(payload.companyId)
      .update({
        companyAdmins: firebase.firestore.FieldValue.arrayUnion(payload.admin),
      })
      .then(function () {
        //store.dispatch("removeUserCompany", payload.admin.id); //remove the company from the user
        // commit("addCompanyAdmin", payload.admin);
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  },

  async demoteCompanyAdminByUserId({ commit }, payload) {
    db.collection("companies")
      .doc(payload.companyId)
      .update({
        companyAdmins: firebase.firestore.FieldValue.arrayRemove(payload.admin),
      })
      .then(function () {
        //store.dispatch("removeUserCompany", payload.admin.id); //remove the company from the user
        // commit("removeCompanyAdmin", payload.admin);
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  },

  //INVITES
  async inviteUserToCompany({ commit }, payload) {
    var ref = db.collection("companyInvites").doc();
    ref
      .set({
        companyId: payload.companyId,
        companyName: payload.companyName,
        sender: payload.sender,
        senderEmail: payload.senderEmail,
        targetEmail: payload.targetEmail,
        createdDate: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(function () {
        store.dispatch("getCompanyInvitesByCompanyId", payload.companyId);
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  },

  async getCompanyInvitesByCompanyId({ commit }, companyId) {
    const company = companyId;

    var ref = db.collection("companyInvites");
    ref
      .where("companyId", "==", company)
      .get()
      .then(function (querySnapshot) {
        const collection = [];
        querySnapshot.forEach(function (doc) {
          collection.push({ id: doc.id, invites: doc.data() });
        });
        commit("setCompanyInvites", collection); //onkelbob
      })
      .catch(function (error) {
        console.error("Error getting documents: ", error);
      });
  },

  async acceptCompanyInvitation({ commit }, payload) {
    const companyId = payload.invites.companyId;
    const inviteId = payload.id;
    const currentUser = await firebase.getCurrentUser();

    db.collection("companies")
      .doc(companyId)
      .update({
        companyEmployees: firebase.firestore.FieldValue.arrayUnion(
          currentUser.uid
        ),
      })
      .then(function () {
        store.dispatch("updateUserCompany", companyId);
        store.dispatch("deleteCompanyInviteById", payload);
        // commit("setCollaborationMembers", existingMembers);
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  },

  async deleteCompanyInviteById({ commit }, payload) {
    db.collection("companyInvites")
      .doc(payload.id)
      .delete()
      .then(function () {
        store.dispatch(
          "getCompanyInvitesByCompanyId",
          payload.invites.companyId
        );
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  },
  //decline for the user..
  async deleteUsersCompanyInviteById({ commit }, payload) {
    db.collection("companyInvites")
      .doc(payload.id)
      .delete()
      .then(function () {
        store.dispatch("getCompanyInvitesByUserId");
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  },

  // FOR DASHBOARD
  async getCompanyInvitesByUserId({ commit }) {
    const currentUser = await firebase.getCurrentUser();

    var ref = db.collection("companyInvites");
    ref
      .where("targetEmail", "==", currentUser.email)
      .get()
      .then(function (querySnapshot) {
        const collection = [];
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          collection.push({ id: doc.id, invites: doc.data() });
        });
        commit("setUsersCompanyInvites", collection); //onkelbob
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  },

  //collaborations offers
  async newCompanyCollaborationOffer({ commit }, payload) {
    var ref = db.collection("collaborationOffers").doc();
    const refId = ref.id;
    ref
      .set({
        collaborationId: payload.collaborationId,
        collaborationTitle: payload.collaborationTitle,
        collaborationTargetSemester: payload.collaborationSemester,
        companyId: payload.user.company,
        createdDate: firebase.firestore.FieldValue.serverTimestamp(),
        lastEdited: firebase.firestore.FieldValue.serverTimestamp(),
        status: 1,
        offerDetails: payload.offerDetails,
        companyDetails: payload.companyDetails,
        user: payload.user,
      })
      .then(function (docRef) {
        router.push("/collaborations/" + payload.collaborationId);
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  },

  async getCompanyOffersById({ commit }, payload) {
    var ref = db.collection("collaborationOffers");
    ref
      .where("user.company", "==", payload.company)
      .get()
      .then(function (querySnapshot) {
        const collection = [];
        querySnapshot.forEach(function (doc) {
          //if the offer isn't deleted or collaborating
          const status = doc.data().status;
          if (status > 0 && status !== 4) {
            //&& doc.data().status < 4
            collection.push({ id: doc.id, offer: doc.data() });
          }
        });
        commit("setCompanyOffers", collection);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  },

  async getCompanyCollaborationsById({ commit }, companyId) {
    var ref = db.collection("collaborationOffers");
    ref
      .where("user.company", "==", companyId)
      .get()
      .then(function (querySnapshot) {
        const collection = [];
        querySnapshot.forEach(function (doc) {
          //if the offer isn't deleted or collaborating
          if (doc.data().status === 4) {
            collection.push({ id: doc.id, offer: doc.data() });
          }
        });
        // console.log("collectionLength: " + collection.length)
        commit("setCompanyCollaborations", collection);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  },
  async getSubscriptionForCode({ commit }, payload) {
    const token = localStorage.getItem("auth-token");
    await axios
      .get(`${process.env.VUE_APP_API_BASE_URL}subscriptions/${payload}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => commit("setSubscription", res.data.payload))
      .catch((err) => commit("setSubscription", null));
  },
  async createUserSubscription({ commit }, payload) {
    const token = localStorage.getItem("auth-token");
    if (payload.isPublic) {
      const data = {
        company_id: payload.company_id,
        company_name: payload.company_name,
        user_id: payload.user_id,
        user_name: payload.user_name,
        user_email: payload.user_email,
        subscription_id: payload.subscription_id,
      };
      const res = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}subscriptions/public/${payload.subscription_id}`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    } else {
      const data = {
        code: payload.code,
        company_id: payload.company_id,
        company_name: payload.company_name,
        user_id: payload.user_id,
        user_name: payload.user_name,
        user_email: payload.user_email,
        subscription_id: payload.subscription_id,
      };
      const res = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}subscriptions/${payload.code}`,
        data,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    }
  },
  // get as list of all the public subscriptions
  async getPublicSubscriptions({ commit }, payload) {
    const token = localStorage.getItem("auth-token");
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}subscriptions`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      commit('setPublicSubscriptions', res.data.payload)
    } catch (error) {
      console.log(error);
    }
  },
  async updateCompanySubscriptionId({ commit }, payload) {
    var ref = db.collection("companies").doc(payload.id);
    var refId = payload.id;
    let ext;

    ref
      .set(
        {
          subscriptionId: payload.subscriptionId,
        },
        { merge: true }
      )
      .then(() => {
        //nothing to do after the promise
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  },
  async getCurrentSubscription({ commit }, payload) {
    const token = localStorage.getItem("auth-token");
    try {
      const res = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}subscriptions/subscription/${payload}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      commit("setSubscription", res.data.payload);
      return res.data.payload;
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setCompany(state, payload) {
    state.companyName = payload.companyName;
    state.companyIdentificationNumber = payload.companyIdentificationNumber;
    state.billingEmail = payload.billingEmail;
    state.billingCountry = payload.billingCountry;
    state.billingAddress = payload.billingAddress;
    state.billingPostcode = payload.billingPostcode;
    state.billingCity = payload.billingCity;
  },
  setCompanyEmployees(state, payload) {
    state.companyEmployees = payload;
  },
  removeCompanyEmployee(state, payload) {
    var result = [];
    var newCompanyEmployees = state.companyEmployees;
    result = newCompanyEmployees.filter((item) => item !== payload);
    state.companyEmployees = result;
  },
  setCompanyAdmins(state, payload) {
    state.companyAdmins = payload;
  },
  // removeCompanyAdmin(state, payload) {
  //   var result = [];
  //   var newCompanyAdmin1 = state.companyAdmins;
  //   result = newCompanyAdmin1.filter(item => item !== payload);
  //   state.companyAdmins = result;
  // },
  // addCompanyAdmin(state, payload) {
  //   state.companyAdmins.push(payload);
  // },
  setCompanyInvites(state, payload) {
    state.companyInvites = payload;
  },
  setUsersCompanyInvites(state, payload) {
    state.usersCompanyInvites = payload;
  },
  setCompanyDetails(state, payload) {
    state.companyDetails = payload;
  },
  setCompanyOffers(state, payload) {
    state.companyOffers = payload;
  },
  setCompanyCollaborations(state, payload) {
    state.companyCollaborations = payload;
  },
  setSubscription(state, payload) {
    state.subscription = payload;
  },
  setPublicSubscriptions(state, payload){
    state.publicSubscriptions = payload
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};

