<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  //placeholder view, see router/index.js for children views
}
</script>


<style lang="scss">
html, body {
  height: 100%;
}
#app {
  height: 100%;
}
</style>