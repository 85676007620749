import Vue from 'vue'
import Vuex from 'vuex'
import admin from './modules/admin.js'
import collaboration from './modules/collaboration.js'
import company from './modules/company.js'
import global from './modules/globals.js'
import mailchimp from './modules/mailchimp.js'
import mails from './modules/mails.js'
import signup from './modules/signup.js'
import user from './modules/user.js'
import event from './modules/events.js'
import master from './modules/master.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: { 
    user,
    global,
    collaboration,
    company,
    admin,
    mailchimp,
    mails,
    signup,
    event,
    master
  }
})
