import firebase from 'firebase/app'
import "firebase/auth"
import 'firebase/firestore'
import "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBM3IA7s4qYBpuhl7hYM_1-IFGqMeMAEDE",
    authDomain: "excelerate-stage.firebaseapp.com",
    databaseURL: "https://excelerate-stage-default-rtdb.firebaseio.com",
    projectId: "excelerate-stage",
    storageBucket: "excelerate-stage.appspot.com",
    messagingSenderId: "182533769748",
    appId: "1:182533769748:web:741f000a2c256318e23501",
    measurementId: "G-FQTDSZDC1G"
  };
  const firebaseApp = firebase.initializeApp(firebaseConfig)

firebase.analytics();   

  
firebase.getCurrentUser = () => {
    // console.log("getCurrentUser");
     return new Promise((resolve, reject) => {
         const unsubscribe = firebase.auth().onAuthStateChanged(user => {
             unsubscribe();
            resolve(user);
         }, reject);
     })
};
export { firebaseApp }
export const db = firebase.firestore();
export default firebase;
