import firebase from 'firebase/app';
import 'firebase/auth';
import { db } from '../../firebase'


const state = {

    emailTemplates: [],
    emailTestStatus: 'idle',

};
const getters = {

    emailTemplates(state) {
        return this.emailTemplates;
    }
};
const actions = {

    sendMail({commit}, payload) {
        // commit("setEmailTestStatus", 'sending..');

        // console.error("sendMail");
        // console.table(payload);

        if(typeof payload.username === typeof undefined) {
            payload.username = "";
        }
        if(typeof payload.firstName === typeof undefined) {
            payload.firstName = "";
        }
        if(typeof payload.lastName === typeof undefined) {
            payload.lastName = "";
        }
        if(typeof payload.param1 === typeof undefined) {
            payload.param1 = "";
        }
        if(typeof payload.param2 === typeof undefined) {
            payload.param2 = "";
        }
        if(typeof payload.param3 === typeof undefined) {
            payload.param3 = "";
        }
        if(typeof payload.param4 === typeof undefined) {
            payload.param4 = "";
        }
        if(typeof payload.param5 === typeof undefined) {
            payload.param5 = "";
        }
        if(typeof payload.param6 === typeof undefined) {
            payload.param6 = "";
        }
        if(typeof payload.param7 === typeof undefined) {
            payload.param7 = "";
        }
        db.collection('mail').add({
            to: payload.toEmail,
            template: {
                name: payload.template,
                data: {
                    username: payload.username,
                    firstName: payload.firstName,
                    lastName: payload.lastName,
                    param1: payload.param1,
                    param2: payload.param2,
                    param3: payload.param3,
                    param4: payload.param4,
                    param5: payload.param5,
                    param6: payload.param6,
                    param7: payload.param7,
                }
            },
        });
        // console.log("sendMail DONE!22");
        // commit("setEmailTestStatus", 'done');
    },


    sendVerificationMail({commit}, payload) {
        // commit("setEmailTestStatus", 'sending..');

        // console.log("MAIL EVENT");
        // console.table(payload);

        db.collection('mail').add({
            to: payload.toEmail,
            template: {
                name: 'UserVerifyEmail',
                data: {
                    code: payload.verificationCode, 
                }
            },
          });

        console.log("sendVerificationMail done");
        // commit("setEmailTestStatus", 'done');

    },

    // mailEvent({commit}, payload) {
    //     commit("setEmailTestStatus", 'sending..');

    //     console.log("MAIL EVENT");
    //     console.table(payload);

    //     //  db.collection('mail').add({
    //     //      to: payload.toEmail,
    //     //      template: {
    //     //          name: payload.template,
    //     //          data: {
    //     //              username: payload.username,
    //     //              firstName: payload.firstName,
    //     //              lastName: payload.lastName,
    //     //              returnUrl: payload.returnUrl,
    //     //              param1: payload.param1,
    //     //              param2: payload.param2,
    //     //              param3: payload.param3,
    //     //              param4: payload.param4,
    //     //              param5: payload.param5,
    //     //              param6: payload.param6,
    //     //              param7: payload.param7,
    //     //              param8: payload.param8,
    //     //              param9: payload.param9,
    //     //              param10: payload.param10,
    //     //              param11: payload.param11,
    //     //              param12: payload.param12,
    //     //          }
    //     //      },
    //     //    });

    //     console.log("sendMail DONE!22");
    //     commit("setEmailTestStatus", 'done');
    // },



    async getEmailTemplates({ commit }) {
        db
        .collection("mailTemplates")
        .get()
        .then(function(querySnapshot) {
            const collection = [];
            querySnapshot.forEach(doc => {
              collection.push({ "id": doc.id, "template": doc.data() });
             });
            commit("setEmailTemplates", collection);
        });
      },
}

const mutations = {
    setEmailTestStatus(state, payload) {
        state.emailTestStatus = payload;
    },

    setEmailTemplates(state, payload) {
        state.emailTemplates = payload;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}