/* eslint-disable no-unreachable */
import 'firebase/auth';

import axios from 'axios';
import { db } from '../../firebase';
import firebase from 'firebase/app';
import router from '../../router';
import store from '@/store';
// updated from using axios in all files to a global config
import { HTTP } from '../../axios/axios';

const state = {
  userId: null,

  firstName: null,
  lastName: null,

  status: null,
  email: null,
  role: null,
  phoneNumber: null,
  //company specific
  language: null,
  isAuthorized: null,
  jobTitle: null,
  company: null, //en, dk, de, se, no, fi... etc..
  // companyName: null,
  selectedCollabByCompany: null,

  //student specific
  graduatingSemester: null,
  country: null,
  institution: null,
  faculty: null,
  degree: null,
  area: null,
  risingStarStepsCompleted: [],
  allTokenSlots: null,
  thesisSlotsUsed: null,
  userSubscriptionId: null,
  secondaryArea: null,
  researchInterests: [],
  thesisStatus: null,
  // collaborations: [],

  verificationCode: null,
  verified: null,

  createdDate: '',
  favorites: [],
  interests: {},
  companyInvites: [],
  currentProgress: null,

  error: null,
  isCurrentPasswordError: false,
  isPasswordUpdated: false,
  isNewPasswordRequested: false,
  process: {},
  designation: null,

  onboarding: {},
  isOnboarding: false,
  designationList: null,
  failCode: null,
  universities: null,
  verifying: null,
};

const getters = {
  selectedCollabByCompany(state) {
    state.selectedCollabByCompany;
  },
  userId(state) {
    return state.userId;
  },
  firstName(state) {
    return state.firstName;
  },
  lastName(state) {
    return state.lastName;
  },
  verifyingUser(state) {
    return state.verifying
  },
  status(state) {
    return state.status;
  },
  email(state) {
    return state.email;
  },
  role(state) {
    return state.role;
  },
  phoneNumber(state) {
    return state.phoneNumber;
  },
  jobTitle(state) {
    return state.jobTitle;
  },
  country(state) {
    return state.country;
  },
  language(state) {
    return state.language;
  },
  company(state) {
    return state.company;
  },
  degree(state) {
    return state.degree;
  },
  institution(state) {
    return state.institution;
  },
  faculty(state) {
    return state.faculty;
  },
  area(state) {
    return state.area;
  },
  graduatingSemester(state) {
    return state.graduatingSemester;
  },
  favorites(state) {
    return state.collaborationFavorites;
  },
  verified(state) {
    return state.verified;
  },
  verificationCode(state) {
    return state.verificationCode;
  },
  error(state) {
    return state.error;
  },
  process(state) {
    return state.process;
  },
  isCurrentPasswordError(state) {
    return state.isCurrentPasswordError;
  },
  isPasswordUpdated(state) {
    return state.isPasswordUpdated;
  },
  isNewPasswordRequested(state) {
    return state.isNewPasswordRequested;
  },
  accountDeleted(state) {
    return state.accountDeleted;
  },
  requestAccountDeletion(state) {
    return state.requestAccountDeletion;
  },
  allTokenSlots(state) {
    return state.allTokenSlots;
  },
  thesisSlotsUsed(state) {
    return state.thesisSlotsUsed;
  },
  userSubscriptionId(state) {
    return state.userSubscriptionId;
  },
  onboardingCompany(state) {
    return state.onboarding.company;
  },
  onboardingData(state) {
    return state.onboarding.userDetails;
  },
  designationList(state) {
    return state.designationList;
  },
  isOnboarding(state) {
    return state.isOnboarding;
  },
  failCode(state) {
    return state.failCode;
  },
  universities(state) {
    return state.universities;
  },
  getFirstName(state) {
    return state.firstName;
  },
  getLastName(state) {
    return state.lastName;
  },
  getAuthorized(state) {
    return state.isAuthorized;
  },
  currentProgress: (state) => state.currentProgress,
};

const actions = {
  async signUpActionLive({ commit, dispatch }, payload) {
    // commit('setStatus', 'loading') //todo master loading
    try {
      const firebaseResponse = await firebase
        .auth()
        .createUserWithEmailAndPassword(payload.email, payload.password);

      // we get the uid from the above api call once the user has been successfully created and use
      //this id to create the user from the laravel app
      if (firebaseResponse.user.uid) {
        const verificationCode = Math.floor(100000 + Math.random() * 900000);
        try {
          const createUserResponse = await HTTP.post('create-user', {
            ...payload,
            verificationCode,
            docId: firebaseResponse.user.uid,
          });
          // once we get a success response, we hit the create user api.
          if (createUserResponse.data.data.status == 'success') {
            localStorage.setItem(
              'auth-token',
              createUserResponse.data.data.payload.token
            );
            localStorage.setItem(
              'user_code',
              createUserResponse.data.data.payload.data.qrcode
            );
            localStorage.setItem(
              'uid',
              createUserResponse.data.data.payload.data.id
            );
            commit('setUserId', firebaseResponse.user.uid);
            commit('setStatus', 'new');
            commit('setRole', payload.payload);
            commit('setUserEmail', payload.email);
            commit('setVerificationCode', verificationCode); // something is causing this to not be saved in the store
            db.collection('users')
              .doc(firebaseResponse.user.uid)
              .set(
                // this is the one we use
                {
                  isAuthorized:
                    payload.payload === 'Professional' ? false : true,
                  email: payload.email,
                  status: 'new',
                  isDisabled: 0,
                  role: payload.payload, // role changed to payload
                  referralCode: payload?.referralCode
                    ? payload.referralCode
                    : null,
                  designation: '',
                  signupStep: '',
                  bachelorInstitution: '',
                  bachelorArea: '',
                  bachelorCustomInstitutionName: '',
                  bachelorGraduatingYear: '',
                  showGrades: '',
                  customBachelorInstitutionName: '',
                  customBachelorStudyProgram: '',
                  firstName: '',
                  isTalent: false,
                  isCourses: false,
                  isCompetencies: false,
                  isExp: false,
                  currentProgress: 0,
                  hasUploadedTranscript: null,
                  showVerificationMessage: null,
                  hasSubProgramme: false,
                  transcriptName: null,
                  currentSemester: null,
                  profileBio: null,
                  passionAndMotivation: null,
                  specializationCourseOne: null,
                  specializationCOurseTwo: null,
                  startedTalent: false,
                  // risingStarPoints: 0,
                  risingStarStepsCompleted: [],
                  researchInterests: [],
                  thesisStatus: null,
                  careerMatching: false,
                  workExperiences: { 1: {} }, // might break
                  educationHistory: {
                    1: { 1: {} },
                    2: { 1: {} },
                    3: { 1: {} },
                    4: { 1: {} },
                    5: { 1: {} },
                    6: { 1: {} },
                    7: { 1: {} },
                    8: { 1: {} },
                    9: { 1: {} },
                    10: { 1: {} },
                  },
                  semesterCourseCount: {
                    1: [1],
                    2: [1],
                    3: [1],
                    4: [1],
                    5: [1],
                    6: [1],
                    7: [1],
                    8: [1],
                    9: [1],
                    10: [1],
                  },
                  lastName: '',
                  currentDegree: '',
                  phoneNumber: '',
                  language: '',
                  country: 'Denmark',
                  institution: '',
                  interests: 0,
                  subscription: '',
                  subscriptionPlan: '',
                  degree: '',
                  faculty: '',
                  area: '',
                  jobTitle: '',
                  company: '',
                  collaborations: '',
                  graduatingSemester: '',
                  favorites: [],
                  verified: false,
                  verificationCode: verificationCode,
                  createdDate: firebase.firestore.FieldValue.serverTimestamp(),
                  description: '',
                  passion: '',
                  jobs: [],
                  hardSkills: {
                    skill_one: '',
                    skill_two: '',
                    skill_three: '',
                    skill_four: '',
                    skill_five: '',
                  },
                  coreSkills: {
                    skill_one: '',
                    skill_two: '',
                    skill_three: '',
                    skill_four: '',
                    skill_five: '',
                  },
                  isCompleted: false,
                },
                { merge: true }
              );
            // firebase
            //   .auth()
            //   .createUserWithEmailAndPassword(payload.email, payload.password)
            //   .then((response) => {
            //     const verificationCode = Math.floor(
            //       100000 + Math.random() * 900000
            //     );
            //     // const verificationEmailData = {
            //     //   toEmail: payload.email,
            //     //   verificationCode: verificationCode,
            //     // };
            //     //async??
            //     db.collection('users')
            //       .doc(response.user.uid)
            //       .set(
            //         {
            //           email: payload.email,
            //           status: 'new',
            //           isDisabled: 0,
            //           role: payload.payload, // role changed to payload
            //           referralCode: payload?.referralCode
            //             ? payload.referralCode
            //             : null,
            //           designation: '',
            //           signupStep: '',
            //           firstName: '',
            //           isTalent: false,
            //           isCourses: false,
            //           isCompetencies: false,
            //           isExp: false,
            //           currentProgress: 0,
            //           hasUploadedTranscript: null,
            //           transcriptName: null,
            //           currentSemester: null,
            //           profileBio: null,
            //           passionAndMotivation: null,
            //           secondaryArea: null,
            //           startedTalent: false,
            //           risingStarPoints: 0,
            //           risingStarStepsCompleted: [],
            //           researchInterests: [],
            //           thesisStatus: null,
            //           workExperiences: { 1: null }, // might break
            //           lastName: '',
            //           phoneNumber: '',
            //           language: '',
            //           country: 'Denmark',
            //           institution: '',
            //           interests: 0,
            //           subscription: '',
            //           subscriptionPlan: '',
            //           degree: '',
            //           faculty: '',
            //           area: '',
            //           jobTitle: '',
            //           company: '',
            //           collaborations: '',
            //           graduatingSemester: '',
            //           favorites: [],
            //           verified: false,
            //           verificationCode: verificationCode,
            //           createdDate:
            //             firebase.firestore.FieldValue.serverTimestamp(),
            //             description: '',
            //             passion: '',
            //             jobs: [],
            //             hardSkills: {
            //               skill_one: '',
            //               skill_two: '',
            //               skill_three: '',
            //               skill_four: '',
            //               skill_five: '',
            //             },
            //             coreSkills: {
            //               skill_one: '',
            //               skill_two: '',
            //               skill_three: '',
            //               skill_four: '',
            //               skill_five: '',
            //             },
            //             isCompleted: false,
            //           //From DP- 3
            //         },
            //         { merge: true }
            //       )
            //   })
            //   .catch((error) => {
            //     commit('setError', error.message);
            //   });
            if (payload.payload === 'Student') {
              router.push('/user/student-welcome');
            } else {
              router.push('/user/welcome');
            }
          } else {
            commit('setError', 'failed to create user, please try again');
          }
        } catch (error) {
          //get current user and delete from firebase
          commit('setError', 'failed to create user, please try again');
        }
      }
      // }
      const res = 'nada';
      // console.log("🚀 ~ file: user.js:159 ~ signUpActionLive ~ payload", payload)
      // if (res == 'success') {
      //   // not removing this code for now
      //   firebase
      //     .auth()
      //     .createUserWithEmailAndPassword(payload.email, payload.password)
      //     .then((response) => {
      //       const verificationCode = Math.floor(
      //         100000 + Math.random() * 900000
      //       );
      //       const verificationEmailData = {
      //         toEmail: payload.email,
      //         verificationCode: verificationCode,
      //       };
      //       //async??
      //       db.collection('users')
      //         .doc(response.user.uid)
      //         .set(
      //           {
      //             email: payload.email,
      //             status: 'new',
      //             isDisabled: 0,
      //             role: payload.payload, // role changed to payload
      //             referralCode: payload?.referralCode
      //               ? payload.referralCode
      //               : null,
      //             designation: '',
      //             signupStep: '',
      //             firstName: '',
      //             isTalent: false,
      //             isCourses: false,
      //             isCompetencies: false,
      //             isExp: false,
      //             currentProgress: 0,
      //             hasUploadedTranscript: null,
      //             transcriptName: null,
      //             currentSemester: null,
      //             profileBio: null,
      //             passionAndMotivation: null,
      //             secondaryArea: null,
      //             startedTalent: false,
      //             risingStarPoints: 0,
      //             risingStarStepsCompleted: [],
      //             researchInterests: [],
      //             thesisStatus: null,
      //             workExperiences: { 1: null }, // might break
      //             lastName: '',
      //             phoneNumber: '',
      //             language: '',
      //             country: 'Denmark',
      //             institution: '',
      //             interests: 0,
      //             subscription: '',
      //             subscriptionPlan: '',
      //             degree: '',
      //             faculty: '',
      //             area: '',
      //             jobTitle: '',
      //             company: '',
      //             collaborations: '',
      //             graduatingSemester: '',
      //             favorites: [],
      //             verified: false,
      //             verificationCode: verificationCode,
      //             createdDate: firebase.firestore.FieldValue.serverTimestamp(),
      //             //From DP- 3
      //             description: '',
      //             passion: '',
      //             jobs: [],
      //             hardSkills: {
      //               skill_one: '',
      //               skill_two: '',
      //               skill_three: '',
      //               skill_four: '',
      //               skill_five: '',
      //             },
      //             coreSkills: {
      //               skill_one: '',
      //               skill_two: '',
      //               skill_three: '',
      //               skill_four: '',
      //               skill_five: '',
      //             },
      //             isCompleted: false,
      //           },
      //           { merge: true }
      //         )
      //         .then(function () {
      //           commit('setUserId', response.user.uid);
      //           commit('setStatus', 'new');
      //           // dispatch("authenticateBackend", state.userId);
      //           router.push('/user/welcome');
      //         })
      //         .catch(function () {
      //           // console.error("Error writing document: ", error);
      //         });
      //     })
      //     .catch((error) => {
      //       commit('setError', error.message);
      //     });
      // }
      // if(response.data.data)
      // eslint-disable-next-line no-unreachable
    } catch (error) {
      console.log({ error });
      commit('setError', error.message);
    }
  },

  // hardSkills({dispatch},payload){
  //   firebase.app().storage("gs://excelerate2020.appspot.com/hardskills.json");
  // },

  /*
   * AUTH!
   */

  async signInAction({ dispatch, commit }, payload) {
    try {
      const response = await HTTP.post('login', payload);
      // dispatch("getDesignationList");
      localStorage.setItem('auth-token', response.data.data.payload.token);
      localStorage.setItem('uid', response.data.data.payload.user_id);
      const firebaseResponse = await firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password);
      firebase.auth().onAuthStateChanged((user) => {
        console.log(user, 'tf?');
      });
      console.log(firebaseResponse, 'yaaaay');
      dispatch('getDesignationList');
      commit('setUserId', firebaseResponse.user.uid);
      if (response?.data?.data?.payload?.imported_status) {
        dispatch('saveUserInBackend', payload);
      } else {
        localStorage.setItem('auth-token', response.data.data.payload.token);
        localStorage.setItem('uid', response.data.data.payload.user_id);
        localStorage.setItem('user_code', response.data.data.payload.qrcode);
        // will get university because the entire process is broken anyway
        dispatch('getUniversityData');
      }
      router.push('/').catch(() => {});
    } catch (error) {
      commit('setError', error.message);
      console.log('eee', error);
    }
    // firebase
    //   .auth()
    //   .signInWithEmailAndPassword(payload.email, payload.password)
    //   .then((response) => {
    //     commit("setUserId", response.user.uid);
    //     router.push("/");
    //   }).then(response => {
    //     dispatch('authenticateBackend', state.userId)
    //     dispatch('getUser')
    //   })
    //   .catch((error) => {
    //     commit("setError", error.message);
    //   });
  },
  async saveUserInBackend({ dispatch }, payload) {
    try {
      const response = await HTTP.post('login-save', payload);
      localStorage.setItem('auth-token', response.data.data.payload.token);
      // will get university because the entire process is broken anyway
      // dispatch('getUniversityData');
    } catch (error) {
      console.log(error);
      dispatch('signOutAction'); // need to sign out user in case they fail to login in the backend
    }
  },
  autoSignIn({ dispatch, commit }, payload) {
    commit('setUserId', payload.uid);
    dispatch('syncUserByIdFromFirebaseAuth', payload.uid);
  },
  signOutAction({ commit }) {
    firebase
      .auth()
      .signOut()
      .then((response) => {
        commit('setUserId', null);
        commit('setError', null);
        commit('setEmail', null);
        commit('resetUser');
        localStorage.removeItem('auth-token');
        router.push('/user/signin');
      })
      .catch((error) => {
        commit('setError', error.message);
      });
  },

  // not even sure why this exists
  sendUserToWelcomeFlow() {
    router.push('/user/welcome');
  },

  /*
   *  SYNC USER STATE FROM FIREBASE AUTH USERID
   */
  syncUserByIdFromFirebaseAuth({ commit, dispatch }, uid) {
    var docRef = db.collection('users').doc(uid);
    docRef
      .get()
      .then(function (doc) {
        var userData = doc.data();

        if (!isNaN(userData.isDisabled) && userData.isDisabled == 1) {
          // console.log(userData);
          commit('setAccountDeleted', true);
          dispatch('signOutAction');

          return false;
        }
        if (userData.status === 'ok' && userData.verified === false) {
          dispatch('setVerification')
        }

        // commit('setRole', userData.role);
        commit('setEmail', userData.email);
        commit('setUserDetails', userData); //the rest
      })
      .catch(function (error) {
        // console.error("Error getting document:", error);
      });
  },

  async setVerification({commit}) {
    commit('setVerifying', true)
    const currentUser = await firebase.getCurrentUser();
    db.collection('users')
      .doc(currentUser.uid)
      .update({
        verified: true,
      })
      .then(function () {
        commit('setVerificationStatus', true);
        commit('setVerifying', false);
      })
      .catch(function (error) {
      });
  },
  // Forgit Password
  forgotPassword({ commit }, payload) {
    commit('setStatus', 'Sending...');
    var auth = firebase.auth();
    // var user = firebase.auth().currentUser;

    var emailAddress = payload.email;
    var actionCodeSettings = {
      // After password reset, the user will be give the ability to go back
      // to this page.
      url: location.origin + '/user/signin',
      // url: 'https://stageapp.excelerate.careers/user/signin',
      handleCodeInApp: false,
    };
    auth
      .sendPasswordResetEmail(emailAddress, actionCodeSettings)
      .then(function () {
        // Email sent
        // console.log('Email sent');
        commit('setStatus', 'New password requested successfully');
        commit('setNewPasswordRequested', true);
        commit('setError', null);
      })
      .catch(function (error) {
        // An error happened.
        commit('setError', error.message);
        commit(
          'setStatus',
          'There is no user record corresponding to this email'
        );
        commit('setNewPasswordRequested', false);
        // console.log('Error:',error);
      });
  },
  // Change Password
  updatePassword({ commit }, payload) {
    firebase
      .auth()
      .signInWithEmailAndPassword(payload.userEmail, payload.userOldPassword)
      .then(function (user) {
        firebase
          .auth()
          .currentUser.updatePassword(payload.userNewPassword)
          .then(function () {
            //Do something
            commit('setPasswordUpdated', true);
          })
          .catch(function (err) {});
      })
      .catch(function (err) {
        // console.log('Error 2 :',err);
        commit('setCurrentPasswordError', true);
        commit('setError', err.message);
      });
  },
  async updateUserDetails({ commit, dispatch }, payload) {
    const currentUser = await firebase.getCurrentUser();
    if (
      typeof payload.email != 'undefined' &&
      payload.email !== currentUser.email
    ) {
      currentUser
        .updateEmail(payload.email)
        .then(function () {
          commit('setEmail', payload.email);
          commit('setProcess', { status: 'success', message: 'Email Updated' });
        })
        .catch(function (error) {
          // An error happened.
          commit('setProcess', { status: 'error', message: error.message });
          console.log(error);
        });
    }
    console.log(payload, 'updateUserDetails');
    commit('setUserDetails', payload);
    db.collection('users')
      .doc(payload.userId)
      .set(
        {
          // changing the flow because firebase is trash
          // email:payload.email,
          role: payload.role ? payload.role : null,
          firstName: payload.firstName ? payload.firstName : null,
          lastName: payload.lastName ? payload.lastName : null,
          phoneNumber: payload.phoneNumber ? payload.phoneNumber : null,
          signupStep: payload.signupStep ? payload.signupStep : null,
          company: payload.company ? payload.company : null,
          isTalent: payload.isTalent ? payload.isTalent : false,
          isCourses: payload.isCourses ? payload.isCourses : false,
          currentDegree: payload.currentDegree ? payload.currentDegree : null,
          isCompetencies: payload.isCompetencies
            ? payload.isCompetencies
            : false,
          isExp: payload.isExp ? payload.isExp : false,
          hasSubProgramme: payload.hasSubProgramme
            ? payload.hasSubProgramme
            : false,
          specializationCourseOne: payload.specializationCourseOne
            ? payload.specializationCourseOne
            : null,
          specializationCourseTwo: payload.specializationCourseTwo
            ? payload.specializationCourseTwo
            : null,
          language: payload.language ? payload.language : null,
          careerMatching: payload.careerMatching
            ? payload.careerMatching
            : null,
          showVerificationMessage: payload.showVerificationMessage
            ? payload.showVerificationMessage
            : null,
          graduatingSemester: payload.graduatingSemester
            ? payload.graduatingSemester
            : null,
          country: payload.country ? payload.country : null,
          institution: payload.institution ? payload.institution : null,
          interests: payload.interests ? payload.interests : null,
          currentProgress: payload.currentProgress
            ? payload.currentProgress
            : 0,
          // semesterCourseCount: {
          //   1: 1,
          //   2: 1,
          //   3: 1,
          //   4: 1,
          //   5: 1,
          //   6: 1,
          //   7: 1,
          //   8: 1,
          //   9: 1,
          //   10: 1,
          // },
          designation: payload.designation ? payload.designation : null,
          bachelorInstitution: payload.bachelorInstitution
            ? payload.bachelorInstitution
            : null,
          bachelorArea: payload.bachelorArea ? payload.bachelorArea : null,
          bachelorCustomInstitutionName: payload.bachelorCustomInstitutionName
            ? payload.bachelorCustomInstitutionName
            : null,
          bachelorGraduatingYear: payload.bachelorGraduatingYear
            ? payload.bachelorGraduatingYear
            : null,
          showGrades: payload.showGrades ? payload.showGrades : false,
          customBachelorInstitutionName: payload.customBachelorInstitutionName
            ? payload.customBachelorInstitutionName
            : null,
          customBachelorStudyProgram: payload.customBachelorStudyProgram
            ? payload.customBachelorStudyProgram
            : null,
          degree: payload.degree ? payload.degree : null,
          faculty: payload.faculty ? payload.faculty : null,
          hasUploadedTranscript: payload.hasUploadedTranscript
            ? payload.hasUploadedTranscript
            : null,
          transcriptName: payload.transcriptName
            ? payload.transcriptName
            : null,
          area: payload.area ? payload.area : null,
          currentSemester: payload.currentSemester
            ? payload.currentSemester
            : null,
          profileBio: payload.profileBio ? payload.profileBio : null,
          passionAndMotivation: payload.passionAndMotivation
            ? payload.passionAndMotivation
            : null,
          educationHistory: payload.educationHistory
            ? payload.educationHistory
            : {
                1: { 1: {} },
                2: { 1: {} },
                3: { 1: {} },
                4: { 1: {} },
                5: { 1: {} },
                6: { 1: {} },
                7: { 1: {} },
                8: { 1: {} },
                9: { 1: {} },
                10: { 1: {} },
              },
          semesterCourseCount: payload.semesterCourseCount
            ? payload.semesterCourseCount
            : {
                1: [1],
                2: [1],
                3: [1],
                4: [1],
                5: [1],
                6: [1],
                7: [1],
                8: [1],
                9: [1],
                10: [1],
              },
          secondaryArea: payload.secondaryArea ? payload.secondaryArea : null,
          startedTalent: payload.startedTalent ? payload.startedTalent : false,
          // risingStarPoints: payload.risingStarPoints
          //   ? payload.risingStarPoints
          //   : 0,
          risingStarStepsCompleted: payload.risingStarStepsCompleted
            ? payload.risingStarStepsCompleted
            : [],
          jobTitle: payload.jobTitle ? payload.jobTitle : null,
          workExperiences: payload.workExperiences
            ? payload.workExperiences
            : { workExperiences: { 1: null } },
          status: payload.status ? payload.status : null,
          subscription: payload.subscription ? payload.subscription : null,
          subscriptionPlan: payload.subscriptionPlan
            ? payload.subscriptionPlan
            : null,
          researchInterests: payload.researchInterests
            ? payload.researchInterests
            : [],
          thesisStatus: payload.thesisStatus ? payload.thesisStatus : null,

          //From DP- 3
          description: dataSanitation(payload, 'description'),
          passion: dataSanitation(payload, 'passion'),
          jobs: dataSanitation(payload, 'jobs'),
          hardSkills: dataSanitation(payload, 'hardSkills'),
          coreSkills: dataSanitation(payload, 'coreSkills'),
          isCompleted: dataSanitation(payload, 'isCompleted'),
          // verified: payload.verified,
          // verificationCode: payload.verificationCode,
          // From DP - 6
        },
        { merge: true }
      )
      .then(function () {
        dispatch('updateUserData', payload); 
        commit('setProcess', { status: 'success', message: '' });
      })
      .catch(function (error) {
        console.error('Error writing document: ', error);
        commit('setProcess', { status: 'error', message: error.message });
      });
  },
  /* company related */
  async updateUserCompany({ commit, state }, payload) {
    const currentUser = await firebase.getCurrentUser();
    commit('setCompany', payload);
    db.collection('users')
      .doc(currentUser.uid)
      .update({
        company: payload,
      })
      .then(function () {
        if (state.selectedCollabByCompany) {
          router.push('/collaborations/' + state.selectedCollabByCompany);
        } else {
          router.push('/companies/' + payload);
        }
      })
      .catch(function (error) {
        // console.error("Error writing document: ", error);
      });
  },

  async removeUserCompany({ commit }, payload) {
    const currentUser = await firebase.getCurrentUser();
    db.collection('users')
      .doc(payload)
      .update({
        company: '',
      })
      .then(function () {
        if (currentUser.uid === payload) {
          commit('setCompany', null);
        }
      })
      .catch(function (error) {
        // console.error("Error writing document: ", error);
      });
  },

  async addUserFavorite({ commit }, payload) {
    const currentUser = await firebase.getCurrentUser();
    commit('addFavorites', payload);
    db.collection('users')
      .doc(currentUser.uid)
      .update({
        favorites: firebase.firestore.FieldValue.arrayUnion(payload),
      })
      .then(function () {})
      .catch(function (error) {
        // console.error("Error writing document: ", error);
      });
  },
  async removeUserFavorite({ commit }, payload) {
    const currentUser = await firebase.getCurrentUser();
    commit('removeFavorites', payload);
    db.collection('users')
      .doc(currentUser.uid)
      .update({
        favorites: firebase.firestore.FieldValue.arrayRemove(payload),
      })
      .then(function () {})
      .catch(function (error) {
        // console.error("Error writing document: ", error);
      });
  },

  async updateUserVerification({ commit }, payload) {
    const currentUser = await firebase.getCurrentUser();
    db.collection('users')
      .doc(currentUser.uid)
      .update({
        verified: payload,
      })
      .then(function () {
        commit('setVerificationStatus', true);
        // ! not required and took me 20 minutes to find out what was causing this redirect
        // setTimeout(function () {
        //   router.push("/user/welcome");
        // }, 200);
      })
      .catch(function (error) {
        // console.error("Error writing document: ", error);
      });
  },
  async requestAccountDeletion({ commit }, payload) {
    const currentUser = await firebase.getCurrentUser();
    db.collection('users')
      .doc(currentUser.uid)
      .update({
        isDisabled: 1,
      })
      .then(function () {
        // setTimeout(function() {
        //   router.push("/user/signin");
        // }, 200);
      })
      .catch(function (error) {
        // console.error("Error writing document: ", error);
      });
  },
  async accountDeleted({ dispatch, commit }, payload) {
    commit('setAccountDeleted', payload);
  },
  async sendSubscriptionSelectedEmailToExcelerate({ commit }, payload) {},
  async getAllThesisSlots({ commit }, payload) {
    const token = localStorage.getItem('auth-token');
    try {
      const res = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}subscriptions/all-thesis-slots`,
        {
          subscription_id: payload.subscription,
          company_id: payload.company,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      commit('setAllThesis', res.data.payload);
    } catch (error) {
      console.log(error);
    }
  },
  async getThesisSlotsUsed({ commit }, payload) {
    const token = localStorage.getItem('auth-token');
    try {
      const res = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}subscriptions/total-thesis-slots-used`,
        {
          subscription_id: payload.subscription,
          company_id: payload.company,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      // console.log(res.data.payload)
      commit('setThesisSlotsUsed', res.data.payload);
      // return res.data.payload
    } catch (error) {
      console.log(error);
    }
  },
  async getUser({ commit }, payload) {
    const currentUser = await firebase.getCurrentUser();
    db.collection('users')
      .doc(currentUser.uid)
      .get()
      .then(function (doc) {
        var userData = doc.data();
        commit('setUser', userData);
      })
      .catch(function (error) {
        console.error('Error getting document:', error);
      });
  },
  async updateUserSubscriptionId({ dispatch, commit }, payload) {
    const currentUser = await firebase.getCurrentUser();
    db.collection('users')
      .doc(currentUser.uid)
      .update({
        userSubscriptionId: payload,
      })
      .then(function (res) {
        dispatch('getUserSubscriptionId');
      })
      .catch(function (error) {
        // console.error("Error writing document: ", error);
      });
  },
  async getUserSubscriptionId({ commit }) {
    const currentUser = await firebase.getCurrentUser();
    db.collection('users')
      .doc(currentUser.uid)
      .get()
      .then((res) =>
        commit('setUserSubscriptionId', res.data().userSubscriptionId)
      )
      .catch((err) => console.log(err));
  },
  async startOnboarding({ commit, dispatch }, payload) {
    try {
      const response = await HTTP.post(`getUser`, { encryptedString: payload });
      dispatch('getDesignationList');
      commit('setIsOnboarding', true);
      commit('setOnboardingData', response.data.data.payload);
      commit('setUserName', response.data.data.payload);
      console.log(response.data.data.payload, 'res');
    } catch (error) {
      console.log(error);
    }
  },
  async verifyUser({ commit }, payload) {
    try {
      const res = await HTTP.post('verifyUser', payload);
      //check for code validity
      // console.log(res.data.statusCode == "bad_request", "dddd");
      if (res.data.statusCode == 'bad_request') {
        commit('setFailCode', 'Invalid Code');
      } else {
        console.log(res);
        commit('setFailCode', null);
        commit('setIsOnboarding', true);
        router.push('/professional-signup/onboarding');
      }
    } catch (error) {
      console.log(error);
    }
  },
  async completeOnboarding({ commit, dispatch, state }, payload) {
    try {
      const email = state.onboarding.userDetails.email;
      const firebaseResponse = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, payload.password);
      if (firebaseResponse.user.uid) {
        const res = await HTTP.post('updateDocId', {
          email,
          docId: firebaseResponse.user.uid,
        });
        if (res.data.data.status == 'success') {
          const res = await HTTP.post('updateRestDetails', {
            ...payload,
            docId: firebaseResponse.user.uid,
          });
          if (res.data.data.status != 'success') {
            commit(
              'setFailCode',
              'Failed to update details, please try again.'
            );
          } else {
            commit('setFailCode', null);
            localStorage.setItem('auth-token', res.data.data.payload.token);
            router.push('/dashboard');
          }
        }
      }
    } catch (error) {
      commit('setFailCode', 'Failed to update details, please try again.');
      console.log(error);
    }
  },
  // async createUser({commit}, payload){
  //   try {
  //     const res = await HTTP.post('verifyUser', payload)
  //     commit('setUserData', res.data.data.payload)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // },
  async getDesignationList({ commit }, payload) {
    try {
      const res = await HTTP.get('getDesignation');
      commit('setDesignationList', res.data.data.payload);
    } catch (error) {
      console.log(error);
    }
  },
  async getUniversityData({ commit }, payload) {
    try {
      commit('setFailCode', null);
      const res = await HTTP.get('getUniversityData');
      if (res.status != 200) {
        // this.getUniversityData();
        commit('setFailCode', 'failed to update details');
      } else {
        commit('setUniversities', res.data.payload);
      }
    } catch (error) {
      // this.getUniversityData();
      console.log(error);
    }
  },
  async updateUserData({ commit, dispatch }, payload) {
    try {
      commit('setFailCode', null);
      // console.log(payload, 'payy')
      const res = await HTTP.post('create-user-next', {
        ...payload,
        payload: payload.role,
      }); // Divyansh check this if something fails in API
      if (res.data.data.status !== 'success') {
        commit('setFailCode', 'failed to update details');
      }
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setUserDetails(state, payload) {
    state.email = payload.email;
    state.role = payload.role;
    (state.specializationCourseOne = payload.specializationCourseOne),
      (state.specializationCourseTwo = payload.specializationCourseTwo),
      (state.firstName = payload.firstName);
    state.lastName = payload.lastName;
    state.phoneNumber = payload.phoneNumber;
    state.company = payload.company;
    state.country = payload.country;
    state.language = payload.language;
    state.institution = payload.institution;
    state.interests = payload.interests;
    state.currentProgress = payload.currentProgress;
    state.degree = payload.degree;
    state.secondaryArea = payload.secondaryArea;
    state.startedTalent = payload.startedTalent;
    state.showVerificationMessage = payload.showVerificationMessage;
    // state.risingStarPoints = payload.risingStarPoints;
    state.faculty = payload.faculty;
    state.semesterCourseCount = payload.semesterCourseCount;
    state.area = payload.area;
    state.currentSemester = payload.currentSemester;
    state.jobTitle = payload.jobTitle;
    state.educationHistory = payload.educationHistory;
    state.isAuthorized = payload.isAuthorized;
    state.researchInterests = payload.researchInterests;
    state.thesisStatus = payload.thesisStatus;
    state.transcriptName = payload.transcriptName;
    state.hasUploadedTranscript = payload.hasUploadedTranscript;
    state.status = payload.status;
    state.hasSubProgramme = payload.hasSubProgramme;
    state.risingStarStepsCompleted = payload.risingStarStepsCompleted;
    state.graduatingSemester = payload.graduatingSemester;
    state.createdDate = payload.createdDate;
    state.profileBio = payload.profileBio;
    state.bachelorInstitution = payload.bachelorInstitution;
    state.bachelorArea = payload.bachelorArea;
    state.bachelorCustomInstitutionName = payload.bachelorCustomInstitutionName;
    state.bachelorGraduatingYear = payload.bachelorGraduatingYear;
    state.showGrades = payload.showGrades;
    state.currentDegree = payload.currentDegree;
    state.customBachelorInstitutionName = payload.customBachelorInstitutionName;
    state.customBachelorStudyProgram = payload.customBachelorStudyProgram;
    state.isTalent = payload.isTalent;
    state.careerMatching = payload.careerMatching;
    state.isCourses = payload.isCourses;
    state.isCompetencies = payload.isCompetencies;
    state.isExp = payload.isExp;
    state.passionAndMotivation = payload.passionAndMotivation;
    state.workExperiences = payload.workExperiences;
    state.verified = payload.verified;
    state.verificationCode = payload.verificationCode;
    state.designation = payload.designation;
    state.signupStep = payload.signupStep;
    state.subscription = payload.subscription;
    state.subscriptionPlan = payload.subscriptionPlan;
    state.userSubscriptionId = payload.userSubscriptionId;
    //From DP- 3
    if (payload.role == 'Student') {
      state.description = payload.description;
      state.passion = payload.passion;
      state.jobs = payload.jobs;
      state.hardSkills = payload.hardSkills;
      state.coreSkills = payload.coreSkills;
      state.isCompleted = payload.isCompleted;
    }
  },
  setUserId(state, payload) {
    state.userId = payload;
  },
  setAccountDeleted(state, payload) {
    state.accountDeleted = payload;
  },
  setRole(state, payload) {
    state.role = payload;
  },
  setEmail(state, payload) {
    state.email = payload;
  },
  setFirstName(state, payload) {
    state.firstName = payload;
  },
  setLastName(state, payload) {
    state.lastName = payload;
  },
  setJobTitle(state, payload) {
    state.jobTitle = payload;
  },
  setCompany(state, payload) {
    state.company = payload;
  },
  setCountry(state, payload) {
    state.country = payload;
  },
  setInstitution(state, payload) {
    state.institution = payload;
  },
  setDegree(state, payload) {
    state.degree = payload;
  },
  setFaculty(state, payload) {
    state.faculty = payload;
  },
  setArea(state, payload) {
    state.area = payload;
  },
  removeUser(state) {
    state.user = null;
  },
  setStatus(state, payload) {
    state.status = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  setProcess(state, payload) {
    state.process = payload;
  },
  setCurrentPasswordError(state, payload) {
    state.isCurrentPasswordError = payload;
  },
  setPasswordUpdated(state, payload) {
    state.isPasswordUpdated = payload;
  },
  addFavorites(state, payload) {
    state.favorites.push(payload);
  },
  removeFavorites(state, payload) {
    var favs = state.favorites;
    favs = favs.filter(function (item) {
      return item !== payload;
    });
    state.favorites = favs;
  },
  setVerificationStatus(state, payload) {
    state.verified = payload;
  },
  setNewPasswordRequested(state, payload) {
    state.isNewPasswordRequested = payload;
  },
  setRequestAccountDeletion(state, payload) {
    state.requestAccountDeletion = payload;
  },
  setAllThesis(state, payload) {
    state.allTokenSlots = payload;
  },
  setThesisSlotsUsed(state, payload) {
    state.thesisSlotsUsed = payload;
  },
  setUser(state, payload) {
    state.myUser = payload;
  },
  setUserSubscriptionId(state, payload) {
    state.userSubscriptionId = payload;
  },
  setOnboardingData(state, payload) {
    state.onboarding = payload;
  },
  setDesignationList(state, payload) {
    state.designationList = payload;
  },
  setIsOnboarding(state, payload) {
    state.isOnboarding = payload;
  },
  setFailCode(state, payload) {
    state.failCode = payload;
  },
  setUserEmail(state, payload) {
    state.email = payload;
  },
  setUniversities(state, payload) {
    state.universities = payload;
  },
  setUserName(state, payload) {
    state.firstName = payload.first_name;
    state.lastName = payload.last_name;
  },
  setVerificationCode(state, payload) {
    state.verificationCode = payload;
  },
  setVerifying: (state, payload) => state.verifying = payload,
  resetUser(state) {
    state.email = null;
    state.role = null;
    (state.specializationCourseOne = null), (state.hasSubProgramme = null);
    (state.specializationCourseTwo = null), (state.firstName = null);
    state.lastName = null;
    state.phoneNumber = null;
    state.company = null;
    state.country = null;
    state.language = null;
    state.institution = null;
    state.interests = null;
    state.currentProgress = null;
    state.degree = null;
    state.secondaryArea = null;
    state.startedTalent = null;
    // state.risingStarPoints = null;
    state.faculty = null;
    state.area = null;
    state.currentSemester = null;
    state.jobTitle = null;
    state.educationHistory = null;
    state.researchInterests = null;
    state.thesisStatus = null;
    state.transcriptName = null;
    (state.bachelorInstitution = null),
      (state.bachelorArea = null),
      (state.bachelorCustomInstitutionName = null),
      (state.bachelorGraduatingYear = null),
      (state.customBachelorInstitutionName = null);
    state.customBachelorStudyProgram = null;
    state.hasUploadedTranscript = null;
    state.status = null;
    state.risingStarStepsCompleted = null;
    state.graduatingSemester = null;
    state.createdDate = null;
    state.profileBio = null;
    state.isTalent = null;
    state.careerMatching = null;
    state.isCourses = null;
    state.currentDegree = null;
    state.isCompetencies = null;
    state.isExp = null;
    state.passionAndMotivation = null;
    state.workExperiences = null;
    state.verified = null;
    state.verificationCode = null;
    state.designation = null;
    state.signupStep = null;
    state.isAuthorized = null;
    state.showVerificationMessage = null;
    state.subscription = null;
    state.subscriptionPlan = null;
    state.userSubscriptionId = null;
  },
};
function dataSanitation(payload, key) {
  return typeof payload[key] != 'undefined' ? payload[key] : '';
}
export default {
  state,
  getters,
  actions,
  mutations,
};
